import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card } from "shards-react";
import { Language, API_URL, LIMIT } from "./../config/index";
import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
// import NewDraft from "./../components/blog/NewDraft";
// import Discussions from "./../components/blog/Discussions";
import TopReferrals from "./../components/common/TopReferrals";

const BlogOverview = ({ smallStats }) => {
  const [folioData, setFolioData] = useState({});
  const [cafData, setCafData] = useState({});
  useEffect(() => {
    fetch(`${API_URL}healthStatus`, {
      method: "get",
      headers: {
        "Content-Type": "Application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);
        setFolioData(data.folio);
        setCafData(data.caf);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  console.log("folio data", folioData);
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Folio Alert" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col className="mb-4 col-6">
          <Card small className="mb-4">
            <div
              className="pt-2 pb-2 pl-2 border-bottom"
              style={{ backgroundColor: "#3083f4", color: "#fff" }}
            >
              Bills
            </div>
            <div style={{ overflowY: "auto", height: 500 }}>
              <table class="table">
                <thead>
                  {/* <th scope="col">Bills</th> */}
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Rut Number</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {folioData.bill !== undefined &&
                    folioData.bill.map(item => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.rutNumber}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col className="col-6 mb-4">
          <Card small className="mb-4">
            <div
              className="pt-2 pb-2 pl-2 border-bottom"
              style={{ backgroundColor: "#3083f4", color: "#fff" }}
            >
              Invoices
            </div>
            <div style={{ overflowY: "auto", height: 500 }}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Rut Number</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {folioData.invoice !== undefined &&
                    folioData.invoice.map(item => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.rutNumber}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col className="col-6 mb-4">
          <Card small className="mb-4">
            <div
              className="pt-2 pb-2 pl-2 border-bottom"
              style={{ backgroundColor: "#3083f4 ", color: "#fff" }}
            >
              CreditNote
            </div>
            <div style={{ overflowY: "auto", height: 500 }}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Rut Number</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {folioData.creditnote !== undefined &&
                    folioData.creditnote.map(item => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.rutNumber}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>

      <Row noGutters className="page-header">
        <PageTitle title="CAF Alert" className="text-sm-left mb-3" />
      </Row>
      <Row>
        <Col className="mt-4 mb-4">
          <Card small className="mb-4">
            <div
              className="pt-2 pb-2 pl-2 border-bottom"
              style={{ backgroundColor: "#3083f4 ", color: "#fff" }}
            >
              Invoices
            </div>
            <div style={{ overflowY: "auto", height: 500 }}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Rut Number</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {cafData.invoice !== undefined &&
                    cafData.invoice.map(item => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.rutNumber}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
        <Col className=" mt-4 mb-4">
          <Card small className="mb-4">
            <div
              className="pt-2 pb-2 pl-2 border-bottom"
              style={{ backgroundColor: "#3083f4 ", color: "#fff" }}
            >
              CreditNote
            </div>
            <div style={{ overflowY: "auto", height: 500 }}>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Rut Number</th>
                    <th scope="col">Count</th>
                  </tr>
                </thead>
                <tbody>
                  {cafData.creditnote !== undefined &&
                    cafData.creditnote.map(item => (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.rutNumber}</td>
                        <td>{item.count}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
      {/* Small Stats Blocks */}
      {/* <Row>
      {smallStats.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <SmallStats
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            percentage={stats.percentage}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))}
    </Row> */}

      {/* <Row> */}
      {/* Users Overview */}
      {/* <Col lg="8" md="12" sm="12" className="mb-4">
        <UsersOverview />
      </Col> */}

      {/* Users by Device */}
      {/* <Col lg="4" md="6" sm="12" className="mb-4">
        <UsersByDevice />
      </Col> */}

      {/* Top Referrals */}
      {/* <Col lg="3" md="12" sm="12" className="mb-4">
        <TopReferrals />
      </Col>
    </Row> */}
    </Container>
  );
};

BlogOverview.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

BlogOverview.defaultProps = {
  smallStats: [
    {
      label: "Customers",
      value: "4,390",
      percentage: "4.7%",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        }
      ]
    },
    {
      label: "Pending Requests",
      value: "4",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [1, 2, 3, 3, 3, 4, 4]
        }
      ]
    },
    {
      label: "Total Approved",
      value: "3",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "Total Reject",
      value: "29",
      percentage: "2.71%",
      increase: true,
      decrease: false,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "Requests This Month",
      value: "17,281",
      percentage: "2.4%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [3, 2, 3, 2, 4, 5, 4]
        }
      ]
    }
  ]
};

export default BlogOverview;
