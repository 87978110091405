import React from "react";
import { Row, Col, Form, FormGroup, InputGroup } from "shards-react";
import { API_URL } from "../config";
import { Button, Container, Card } from "shards-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Loader from "react-loader-spinner";
function CertProcess() {
  React.useEffect(() => fillOrganisation(), []);
  const fillOrganisation = () => {
    fetch(`${API_URL}organisations`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          array.push({
            id: "",
            name: "All Organisation"
          });

          res.result.map(item => {
            let obj = item.data;

            array.push({
              id: item._id,
              rutNumber: item.data.rutNumber,
              name: item.data.name
            });
          });

          setorganisationList(array);
          console.log(array);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const [tableArr, setTableArr] = React.useState([]);
  const [rutNumber, setRutNumber] = React.useState("");

  const loadBtnApi = async () => {
    const res = await fetch(
      `${API_URL}certificationProcessSteps?organisationID=${organizationId}&driveID=${auto}&type=${billingId}`,
      {
        method: "GET",
        credentials: "include"
      }
    );
    const data = await res.json();
    if (res.status === 400) {
      console.log(data.message);
      setErr(data.message);
      setLoading(false);
    } else if (res.status === 200) {
      setLoading(false);
      setTableArr(data);
      setShowTables(true);
    }
  };
  const [organizationId, setOrganitationId] = React.useState(0);
  const [billingId, setBillingId] = React.useState("");
  const [autocomplete, setAutocomplete] = React.useState({
    suggest: "",
    nonsuggest: ""
  });
 
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);

  const [organisationList, setorganisationList] = React.useState([]);
  const [showTables, setShowTables] = React.useState(false);

  const BillingList = [
    { id: "", name: "choose one" },
    //{ id: "bill", name: "Boleta" },
    { id: "invoice", name: "Factura" }
  ];

  const handleOrganisationChange = e => {
    let orgID = e.target.value;
    console.log("i am select", orgID);
    organisationList.map(organisation => {
      if(orgID == organisation.id){
        //alert(organisation.rutNumber);
        setRutNumber(organisation.rutNumber);
      }
    })
    setOrganitationId(orgID);
  };

  const handleBillingChange = e => {
    console.log("i am select", e.target.value);
    setBillingId(e.target.value);
  };

  const handeledautocomplete = e => {
    console.log("i am autocomplete", e.target.textContent);
    setAutocomplete({
      suggest: e.target.textContent
    });
  };

  const handelTextField = e => {
    console.log(e.target.value);
    setAutocomplete({
      nonsuggest: e.target.value
    });
  };

  const [arr, setArr] = React.useState([]);

  const getArray = () => {
    fetch(
      `${API_URL}driveFolderList?organisationID=${organizationId}&type=${billingId}`,
      {
        method: "GET",
        credentials: "include"
      }
    )
      .then(res => res.json())
      .then(res => {
        setArr(res);
      })
      .catch(err => {
        console.log("i am error", err);
      });
  };

  React.useEffect(() => getArray(), [organizationId, billingId]);
const[id,setId]= React.useState(-1)
  // const loder = (e) => {
  //   console.log('index',e);
  //   setId(e)
  //   setLoading1(true);
  //   setTimeout(() => {
  //     setLoading1(false);
  //     setId(-1)
  //   }, 2000);
  // };


  let auto;
  if (autocomplete.suggest == undefined) {
    auto = autocomplete.nonsuggest;
  } else if (autocomplete.nonsuggest == undefined) {
    auto = autocomplete.suggest;
  }

  const [err, setErr] = React.useState("");

  const validate = () => {
    setShowTables(false);
    if (organizationId == 0 || organizationId == "") {
      setErr("please select any one organization");
    } else if (billingId == "") {
      setErr("please select billing or invoice");
    } else if (auto == undefined) {
      setErr("please select any driveID");
    } else {
      setErr("");
      setShowcard(true)
      setLoading(true);
      loadBtnApi();
    }
  };
  const onLoadBtnClick = () => {
    validate();
  };
  const[showCard,setShowcard]=React.useState(false)

const startbtnapi=async(e,stepCode)=>{
  setId(e)
  setLoading1(true);
  const res = await fetch(
    `
    
    ${API_URL}certProcess?rutNumber=${rutNumber}&driveID=${auto}&step=${stepCode}
    `,
    {
      method: "GET",
      credentials: "include"
    }
  );
  const data = await res.json();
  if (res.status === 400) {
    console.log(data.message);
    setLoading1(false);
    setId(-1)
   
  } else if (res.status === 200) {
    setLoading1(false);
    setId(-1)
    onLoadBtnClick();
  }


}

  return (
    <Container
      fluid
     className="main-content-container px-4"
  
      style={{ marginBottom: "200px" }}
    >
      <Card small className="mb-4 p-4 mt-3"
       
      >
        <Form>
          <Row form>
            <Col md="3" className="form-group">
              <select
                className="form-control mt-3"
                onChange={e => handleOrganisationChange(e)}
              >
                {organisationList.map(e => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </select>
            </Col>
            <Col md="3" className="form-group">
              <select
                className="form-control mt-3"
                onChange={e => handleBillingChange(e)}
              >
                {BillingList.map(e => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </select>
            </Col>
            <Col md="3" className="form-group">
              <FormGroup>
                <Autocomplete
                  id="combo-box-demo"
                  options={arr}
                  getOptionLabel={option => option}
                  onChange={e => handeledautocomplete(e)}
                  renderInput={params => (
                    <InputGroup className="mb-3 mt-3">
                      <TextField
                        {...params}
                        onChange={e => handelTextField(e)}
                      />
                    </InputGroup>
                  )}
                />
              </FormGroup>
            </Col>
            <Col md="3" className="form-group mt-3">
              <Button
                size="sm"
                theme="success"
                className="mb-2 ml-4"
                onClick={onLoadBtnClick}
              >
                Load
              </Button>
            </Col>
          </Row>
        </Form>

        <div
          className={err == "" ? "d-none" : ""}
          style={{
            color: "white",
            textAlign: "center",
            backgroundColor: "red",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 18
          }}
        >
          {err}
        </div>
      </Card>

      <Card small 
              className={showCard?"mb-4 p-4 mt-3": "d-none"}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={loading}
          />
        </div>

        <table
          className={showTables ? "table table-hover table-bordered" : "d-none"}
        >
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Steps Name</th>
              <th scope="col">Description</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableArr.map((e, index) => {
              return (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{e.name}</td>
                  <td style={{ fontSize: 12, fontWeight: "normal" }}>
                    {e.description.split("\n").map((it, i) => (
                      <div key={"x" + i}>{it}</div>
                    ))}
                  </td>
                  <td>
                    {e.status.split("\n").map((it, i) => (
                      <div key={"x" + i}>{it}</div>
                    ))}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        visible={id==index?loading1:null}
                      />
                    </div>
                 <div style={{
                   display: "flex"
                 }}>
                 <Button
                      size="sm"
                      theme="success"
                      className={!(id==index) ? "mb-2 ml-4" : "d-none"}
                    
                      disabled={e.isCompleted ? "disabled" : null}
       

                      onClick={()=>startbtnapi(index,e.stepCode)}
                    >
                      Start
                    </Button>
                    <Button
                      size="sm"
                      theme="success"
                      className={!(id==index) ? "mb-2 ml-4" : "d-none"}
                    
                     
       

                      onClick={()=>startbtnapi(index,e.stepCode)}
                    >
                      Retry
                    </Button>
                 </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </Container>
  );
}

export default CertProcess;
