import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
// import axios from "axios";
// import { Redirect } from "react-router-dom";
import OtpInput from "react-otp-input";
import Logo from "./../../images/ebol_logo.png";
import PasswordField from "./Password";
import {
  Language,
  API_URL,
  HEADER_ORIGIN,
  HEADER_ORIGIN_KEY,
  updateNumber
} from "./../../config";
import "./login.css";
import $ from "jquery";
import { strings } from "./../../localization/Login";

import { Session } from "bc-react-session";

// import "./style";
export default class Login extends Component {
  state = {
    usercode: "",
    password: "",
    orgcode: "",
    // pass_error: "",
    error: "",
    loader: "",
    forgot_password: false,
    redirect: false,
    have_code: false,
    otp: "",
    verify: false,
    errors: "",
    remember_me: false,
    button: false
  };

  constructor(props) {
    super(props);
    strings.setLanguage(Language);
  }

  // componentDidMount() {
  //   // console.log(cookies.get("login_info"));
  //   Session.start({
  //     payload: "data",
  //     expiration: 1 // (optional) defaults to 1 day
  //   });
  // }

  login() {
    this.setState({
      loader: "fa fa-spinner fa-spin",
      button: true
    });
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    //headers[HEADER_ORIGIN_KEY] = HEADER_ORIGIN;

    let request_data = {
      organisationCode: this.state.orgcode,
      userName: this.state.usercode,
      password: this.state.password
    };
    request_data[HEADER_ORIGIN_KEY] = HEADER_ORIGIN;

    fetch(`${API_URL}authlogin`, {
      mode: "cors",
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(request_data)
    })
      .then(res => {
        if (res.status === 200) {
          fetch(`${API_URL}authlogin`, {
            method: "GET",
            credentials: "include"
          })
            .then(res => res.json())
            .then(async res => {
              let expire = "";

              if (this.state.remember_me) {
                expire = new Date(Date.now() + 86400000 * 999);
              } else {
                expire = new Date(Date.now() + 86400000);
              }

              const data = JSON.stringify(res);

              localStorage.setItem("user_info", data);
              let userId = res._id;
              Session.start({
                payload: data,
                expiration: expire // (optional) defaults to 1 day
              });
              fetch(`${API_URL}organisations/${res.data.organisationID}`, {
                method: 'get',
                credentials: 'include'
              })
                .then((res) => res.json())
                .then(async (res) => {
                  res = JSON.stringify(res);
                  localStorage.setItem('organisation_info', res);
                })
              fetch(`${API_URL}permissions?userid=${userId}`, {
                method: "GET",
                credentials: "include"
              })
                .then(res => res.json())
                .then(async res => {
                  res = res.data.permissions;
                  res = JSON.stringify(res);
                  localStorage.setItem("user_permissions", res);
                  window.location.replace("/dashboard");
                })
                .catch(err => {
                  console.log(err);
                  this.setState({ loading: false });
                });
            })
            .catch(err => { });
        }
        return res.json();
      })
      .then(res => {
        if (res.message) {
          this.setState({
            errors: res.message,
            button: false,
            loader: ""
          });
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  handleClick = e => {
    e.preventDefault();
    // this.login();
    if (
      this.state.usercode === "" ||
      this.state.orgcode === "" ||
      this.state.password === ""
    ) {
      if (this.state.usercode === "") {
        this.setState({
          errors: strings.required_usercode
        });
      } else if (this.state.orgcode === "") {
        this.setState({
          errors: strings.required_orgcode
        });
      } else if (this.state.password === "") {
        this.setState({
          errors: strings.required_password
        });
      }
    } else if (this.state.usercode !== "" && this.state.password !== "") {
      this.setState({
        errors: ""
      });

      // let re =/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/';

      /*if (!re.test(this.state.usercode)) {
        this.setState({
          user_error: "Email is not valid",
        });
      } else*/ {
        this.login();
      }
    }
  };
  handleChange = otp => this.setState({ otp });

  verifyOTP() {
    let otp = this.state.otp;
    if (otp === "1111") {
      this.setState({
        verify: true,
        have_code: false,
        errors: false
      });
    } else {
      this.setState({
        errors: strings.invalid_code
      });
    }
  }

  sendOTP() {
    let email = this.state.usercode;
    if (email === "") {
      this.setState({
        errors: strings.required_usercode
      });
    } else if (this.state.orgcode === "") {
      this.setState({
        errors: strings.required_orgcode
      });
    } else {
      this.setState({
        success: strings.send_success + " your email",
        errors: "",
        usercode: "",
        orgcode: ""
      });
    }
  }
  changePassword() {
    const newPassword = $("#n_password").val();
    const confirmPassword = $("#c_password").val();

    if (newPassword === "" || confirmPassword === "") {
      this.setState({
        errors: strings.fill_fields
      });
    } else {
      if (newPassword !== confirmPassword) {
        this.setState({
          errors: strings.passwords_not_match
        });
      } else {
        // setButton(true);
        // setLoader("fa fa-spinner fa-spin");

        this.setState({
          button: true,
          loader: "fa fa-spinner fa-spin"
        });

        setTimeout(() => {
          this.setState({
            button: false,
            loader: "",
            success: strings.password_success,
            errors: "",
            forgot_password: false
          });

          $("#n_password").val("");
          $("#c_password").val("");
        }, 1000);
      }
    }
  }

  render() {
    return this.state.forgot_password ? (
      <Row>
        <Col lg="12">
          <form>
            <img
              src={Logo}
              alt=""
              height="80"
              id="main_page_logo"
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "50%"
              }}
            />

            {this.state.errors && (
              <div
                className="alert alert-danger alert-dismissible fade show mt-2"
                role="alert"
              >
                {this.state.errors}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => this.setState({ errors: "" })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            {this.state.success && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {this.state.success}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => this.setState({ success: "" })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            {/* <h3>Forgot Password</h3> */}

            {this.state.have_code ? (
              <>
                <h5 className="text-center py-3">{strings.enter_code}</h5>

                <div className="mt-2" style={{ marginLeft: "3rem" }}>
                  <OtpInput
                    value={this.state.otp}
                    onChange={this.handleChange}
                    numInputs={4}
                    separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                    inputStyle={{
                      height: "3rem",
                      width: "3rem",
                      fontWeight: "bold"
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-block mt-4"
                  onClick={e => this.verifyOTP(e)}
                >
                  <i
                    className={this.state.loader}
                    style={{ fontSize: "15px" }}
                  ></i>
                  &nbsp;{strings.verify_code}
                </button>
                <p className="forgot-password text-right">
                  <a
                    href="javascrit:;"
                    className="link_class"
                    onClick={() =>
                      this.setState({
                        forgot_password: false,
                        have_code: false,
                        success: "",
                        errors: "",
                        otp: ""
                      })
                    }
                  >
                    {strings.back_login}
                  </a>
                </p>
              </>
            ) : this.state.verify ? (
              // <h1>new password</h1>
              <>
                {/* <span className="text-danger font-weight-normal alert alert-danger">
                </span> */}
                <PasswordField
                  title={strings.new_password}
                  name="n_password"
                  id="n_password"
                />
                <PasswordField
                  title={strings.confirm_password}
                  name="c_password"
                  id="c_password"
                />

                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={() => this.changePassword()}
                >
                  <i
                    className={this.state.loader}
                    style={{ fontSize: "15px" }}
                  ></i>
                  &nbsp;Change Password
                </button>
                <p className="forgot-password text-right">
                  <a
                    href="javascrit:;"
                    className="link_class"
                    onClick={() =>
                      this.setState({
                        forgot_password: false,
                        have_code: false,
                        verify: false,
                        otp: false
                      })
                    }
                  >
                    {strings.back_login}
                  </a>
                </p>
              </>
            ) : (
              <div>
                <div className="form-group">
                  <label>{strings.usercode}</label>
                  <InputGroup seamless className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">code</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="text"
                      className="form-control textBox"
                      placeholder={strings.usercode}
                      value={this.state.usercode}
                      onChange={e =>
                        this.setState({ usercode: e.target.value })
                      }
                    />
                  </InputGroup>
                </div>

                <div className="form-group">
                  <label>{strings.orgcode}</label>
                  <InputGroup seamless className="mb-3">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">business</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      type="text"
                      className="form-control textBox"
                      placeholder={strings.orgcode}
                      value={this.state.orgcode}
                      onChange={e => this.setState({ orgcode: e.target.value })}
                    />
                  </InputGroup>
                </div>

                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={e => this.sendOTP(e)}
                >
                  <i
                    className={this.state.loader}
                    style={{ fontSize: "15px" }}
                  ></i>
                  &nbsp;&nbsp;{strings.forgot_password_btn}
                </button>
                <div className="d-flex justify-content-between">
                  <p className="forgot-password text-left">
                    <a
                      href="javascrit:;"
                      className="link_class"
                      onClick={() =>
                        this.setState({
                          have_code: true,
                          success: "",
                          errors: ""
                        })
                      }
                    >
                      {strings.have_code}
                    </a>
                  </p>
                  <p className="forgot-password text-right">
                    <a
                      href="javascrit:;"
                      className="link_class"
                      onClick={() => this.setState({ forgot_password: false })}
                    >
                      {strings.back_login}
                    </a>
                  </p>
                </div>
              </div>
            )}
          </form>
        </Col>
      </Row>
    ) : (
      <>
        <Row>
          <Col lg="12">
            <form>
              {/* <h3>Sign In</h3> */}
              <img
                src={Logo}
                alt=""
                height="80"
                id="main_page_logo"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "50%"
                }}
              />
              {this.state.errors && (
                <div
                  className="alert alert-danger alert-dismissible fade show mt-2"
                  role="alert"
                >
                  {this.state.errors}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => this.setState({ errors: "" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  {this.state.success}
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={() => this.setState({ success: "" })}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}

              <div className="form-group">
                <label>RUT de Empresa</label>
                <InputGroup seamless className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">business</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control textBox"
                    placeholder="RUT de Empresa"
                    value={this.state.orgcode}
                    onChange={e => this.setState({ orgcode: e.target.value })}
                    onBlur={() => {
                      let value = updateNumber(this.state.orgcode);
                      this.setState({
                        orgcode: value
                      });
                    }}
                  />
                </InputGroup>
              </div>

              <div className="form-group">
                <label>RUT de usuario</label>
                <InputGroup seamless className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">code</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="text"
                    className="form-control textBox"
                    placeholder="RUT de usuario"
                    value={this.state.usercode}
                    onChange={e => this.setState({ usercode: e.target.value })}
                    onBlur={() => {
                      let value = updateNumber(this.state.usercode);
                      this.setState({
                        usercode: value
                      });
                    }}
                  />
                </InputGroup>
              </div>

              <div className="form-group">
                <label>Clave</label>
                <InputGroup seamless className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">vpn_key</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    type="password"
                    className="form-control"
                    placeholder={"Clave"}
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                </InputGroup>

                {/* <span className="text-danger font-weight-normal">
                  {this.state.pass_error}
                </span> */}
              </div>

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    onClick={() =>
                      this.setState({
                        remember_me: !this.state.remember_me
                      })
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    Recuérdame
                  </label>
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={e => this.handleClick(e)}
                disabled={this.state.button}
              >
                <i
                  className={this.state.loader}
                  style={{ fontSize: "15px" }}
                ></i>
                &nbsp;&nbsp;Ingresar
              </button>
              <p className="forgot-password text-right">
                <a
                  href="javascrit:;"
                  className="link_class"
                  onClick={() =>
                    this.setState({
                      forgot_password: true,
                      errors: ""
                      // pass_error: ""
                    })
                  }
                >
                  ¿Has olvidado tu contraseña?
                </a>
              </p>
            </form>
          </Col>
        </Row>
      </>
    );
  }
}
