import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    overview: "OVERVIEW",
    f_name: "Full name",
    email: "Email",
    phone: "Phone",
    org_name: "Organization Name",
    org_code: "Organization Code",
    user_code: "User Code",
    action: "Action",
    accept: "Accept",
    reject: "Reject",
    edit: "Edit",
    delete: "Delete",
    are_you_sure: "Are you sure?",
    confirm_reject: "You want to reject this request!",
    delete_customer: "You want to delete this customer!",
    yes_button: "Yes, Reject it!",
    yes_delete: "Yes, delete it!",
    rejected: "Rejected!",
    deleted: "Deleted!",
    success_rejected: "The request has been rejected.",
    success_deleted: "The customer has been deleted.",
    inquire_request: "Inquiry Request",
    search_placeholder: "Search Name / Email / Phone / Organization Name.",
    search_placeholder_customers:
      "Search Name / Email / Phone / Organization Name / Organization Code / User Code ."
  },
  es: {
    overview: "OVERVIEW ES",
    f_name: "Full name ES",
    email: "Email ES",
    phone: "Phone ES",
    org_name: "Organization Name ES",
    org_code: "Organization Code ES",
    user_code: "User Code ES",
    action: "Action ES",
    accept: "Accept ES",
    reject: "Reject ES",
    edit: "Edit ES",
    delete: "Delete ES",
    are_you_sure: "Are you sure? ES",
    confirm_reject: "You want to reject this request! ES",
    delete_customer: "You want to delete this customer! ES",
    yes_button: "Yes, Reject it! ES",
    yes_delete: "Yes, delete it! ES",
    rejected: "Rejected! ES",
    deleted: "Deleted! ES",
    success_rejected: "The request has been rejected. ES",
    success_deleted: "The customer has been deleted. ES",
    inquire_request: "Inquiry Request ES",
    search_placeholder: "Search Name / Email / Phone / Organization Name. ES",
    search_placeholder_customers:
      "Search Name / Email / Phone / Organization Name / Organization Code / User Code . ES"
  }
});
