import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    create_customer: "Create User",
    f_name: "Full Name",
    email: "Email",
    go_back: "Go Back",
    create: "Create User",
    edit: "Edit User",
    save: "Save",
    update: "Update",
    edit_profile: "Edit Profile",
    password: "Password",
    update_profile: "Update Profile",
    change_password: "Change Password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    update_password: "Update Password",
    password_not_match: "New password and confirm password not match",
    password_success: "Password changed successfully",
    profile_updated: "Profile updated successfully",
    phone: "Phone",
    org_name: "Organization Name",
    org_code: "Organization Code",
    user_code: "User Code",
    action: "Action",
    accept: "Accept",
    reject: "Reject",
    edit: "Edit",
    delete: "Delete",
    are_you_sure: "Are you sure?",
    confirm_reject: "You want to reject this request!",
    delete_customer: "You want to delete this customer!",
    delete_item: "You want to delete this item!",
    success_item_deleted: "The item has been deleted.",
    yes_button: "Yes, Reject it!",
    yes_delete: "Yes, delete it!",
    rejected: "Rejected!",
    deleted: "Deleted!",
    success_rejected: "The request has been rejected.",
    success_deleted: "The customer has been deleted.",
    inquire_request: "Inquire Request",
    search_placeholder: "Search Name / Email / Phone / Organization Name.",
    search_placeholder_customers:
      "Search Name / Email / Phone / Organization Name / Organization Code / User Code .",
    search_placeholder_items: "Search Item Code / Item Description",
    item_code: "Item Code",
    item_description: "Item Description",
    manage_item: "Manage Item",
    create_item: "Create Item",
    edit_item: "Edit Item",
    save_success: "Record save successfully.",
    go_back: "Go Back",
    fill_fields: "Fill all fields !!",
    users: "Users",
    resubmit_swal_title: "Are you sure?",
    resubmit_swal_msg: "You want resubmit to the SII",
    resubmit_swal_yes_button: "Yes",
    resubmit_swal_no_button: "No",
  },
  es: {
    create_customer: "Create User",
    f_name: "Full Name",
    email: "Email",
    go_back: "Go Back",
    create: "Create User",
    edit: "Edit User",
    save: "Save",
    update: "Update",
    edit_profile: "Edit Profile",
    password: "Password",
    update_profile: "Update Profile",
    change_password: "Change Password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    update_password: "Update Password",
    password_not_match: "New password and confirm password not match",
    password_success: "Password changed successfully",
    profile_updated: "Profile updated successfully",
    phone: "Phone",
    org_name: "Organization Name",
    org_code: "Organization Code",
    user_code: "User Code",
    action: "Action",
    accept: "Accept",
    reject: "Reject",
    edit: "Edit",
    delete: "Delete",
    are_you_sure: "Are you sure?",
    confirm_reject: "You want to reject this request!",
    delete_customer: "You want to delete this customer!",
    delete_item: "You want to delete this item!",
    success_item_deleted: "The item has been deleted.",
    yes_button: "Yes, Reject it!",
    yes_delete: "Yes, delete it!",
    rejected: "Rejected!",
    deleted: "Deleted!",
    success_rejected: "The request has been rejected.",
    success_deleted: "The customer has been deleted.",
    inquire_request: "Inquire Request",
    search_placeholder: "Search Name / Email / Phone / Organization Name.",
    search_placeholder_customers:
      "Search Name / Email / Phone / Organization Name / Organization Code / User Code .",
    search_placeholder_items: "Search Item Code / Item Description",
    item_code: "Item Code",
    item_description: "Item Description",
    manage_item: "Manage Item",
    create_item: "Create Item",
    edit_item: "Edit Item",
    save_success: "Record save successfully.",
    go_back: "Go Back",
    fill_fields: "Fill all fields !!",
    users: "Users",
    resubmit_swal_title: "Estas segura?",
    resubmit_swal_msg: "Quieres volver a enviar a la SII",
    resubmit_swal_yes_button: "Sí",
    resubmit_swal_no_button: "No",
  }
});
