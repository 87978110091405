import React, { Component } from "react";
import { Card, Row, Col, Container, Button, Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { LineChart, ColumnChart, PieChart } from "@opd/g2plot-react";
import { API_URL } from "../../config";
import Loader from "react-loader-spinner";
import swal from "sweetalert2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import ReactDatatable from "@ashvin27/react-datatable";
import "./app.css";
import OrganisationSearch from "./../../components/organisation-search";
import { strings } from "../../localization";
window.Swal = swal;
export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let startDate = mm + "/" + "01" + "/" + yyyy;
    let endDate = mm + "/" + dd + "/" + yyyy;

    this.state = {
      apiEndPoint: "billsummary",
      organisationID: "",
      organisationList: [],
      chartData: [],
      visible: true,
      displayChart: [],
      startDate,
      endDate,
      locale: {
        format: "DD/MM/YYYY"
      },
      showSIIDatePopup: false,
      siiSelectedDate: "",
      siiRecordsPopupLabels: [],
      siiRecordsRowValues: [],
      selectedSIIData: [],
      selectedSIIDocumentName: ""
    };
  }

  componentDidMount() {
    this.fillOrganisation();
    this.getData();
  }

  downloadFile = type => {
    let param = "?type=csv";

    if (this.state.startDateApi || this.state.endDateApi) {
      param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}&type=csv`;
    }
    if (this.state.organisationID)
      param += "&organisationID=" + this.state.organisationID;

    // alert(`${API_URL}summaries/${type}${param}`);
    console.log(`${API_URL}summaries/${type}${param}`)
    fetch(`${API_URL}allSummaries/${type}${param}`, {
      method: "get",
      headers: {
        "Content-Type": "Application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ebol_invoices.csv");
        document.body.appendChild(link);

        link.click(); // This will download the data file named "my_data.csv".
      })
      .catch(err => {
        console.log(err);
      });
  };

  getData() {
    fetch(`${API_URL}charts/webreport/${this.state.apiEndPoint}`, {
      method: "get",
      headers: {
        "Content-Type": "Application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        this.setState({ chartData: data }, () =>
          //this.handleCallback(this.state.startDate,this.state.endDate)
          this.renderCharts(true)
        );
      })
      .catch(e => {
        this.setState({
          displayChart: (
            <>
              <img src={require("./../../images/500.png")} />
            </>
          ),
          visible: false
        });
      });
  }

  getDocumentName() {
    let titleName = "";
    if (this.state.apiEndPoint === "billsummary") {
      titleName = "Bills";
    }
    if (this.state.apiEndPoint === "invoices") {
      titleName = "Invoices"
    }
    if (this.state.apiEndPoint === "creditnotes") {
      titleName = "Creditnotes"
    }
    if (this.state.apiEndPoint === "debitnotes") {
      titleName = "Debitnotes"
    }
    if (this.state.apiEndPoint === "deliverynotes") {
      titleName = "Deliverynotes"
    }
    return titleName;
  }

  handleDateButtonClicked(e, value, labels) {
    e.preventDefault();
    if (value.pendingToSubmitSiiIds !== undefined && value.pendingToSubmitSiiIds !== null && value.pendingToSubmitSiiIds.length > 0) {
      console.log('pendingToSubmitSiiIds', value.pendingToSubmitSiiIds)
      console.log('submitToSiiOrganisationData', value.submitToSiiOrganisationData)
      this.setState({
        showSIIDatePopup: true,
        siiRecordsPopupLabels: labels,
        selectedSIIData: value.submitToSiiOrganisationData,
        siiSelectedDate: value.date,
        selectedSIIDocumentName: this.getDocumentName()
      })
    }
  }

  handleResubmitButtonClicked(event, rawData) {
    event.preventDefault();
    console.log('clicked on last td which raw data are', rawData);
    let apiResponse;
    let statusCode = 0;
    swal
      .fire({
        title: strings.resubmit_swal_title,
        text: strings.resubmit_swal_msg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: strings.resubmit_swal_no_button,
        confirmButtonText: strings.resubmit_swal_yes_button,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          console.log('clicked on pre confirm button')
          // this.resubmitToSIIApi(rawData.pendingToSubmitSiiIds)
          return fetch(`${API_URL}submitPendingDocument?doc_type=${this.state.apiEndPoint}&transactionsToSubmit=${rawData.pendingToSubmitSiiIds}`, {
            method: "GET",
            credentials: "include",
          })
            .then(async (res) => {
              console.log('response of submitPendingDocument api', res)
              console.log('response of submitPendingDocument api statuscode', res.status)
              statusCode = res.status;
              apiResponse = await res.json();
            })
            .catch((err) => {
              console.log('catch error at submitPendingDocument api', err)
              swal.showValidationMessage("Something went wrong")
            })
        },
      })
      .then(result => {
        console.log('result: ', result)
        if (result.isConfirmed) {
          let message = "Records successfully submitted to SII";
          if (apiResponse && apiResponse['message'] !== undefined && apiResponse['message'] !== null) {
            message = apiResponse['message'];
          }
          console.log('message of submitPendingDocument', message)
          if (statusCode === 200) {
            swal.close()
            swal
              .fire({
                title: 'Successfully submitted',
                text: message,
                timer: 2000,
                timerProgressBar: true,
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              })
            setTimeout(() => {
              this.setState({
                chartData: [],
                displayChart: [],
                visible: true
              });
              this.getData();
            }, [2000])
          } else {
            swal
              .fire({
                title: 'Error submitting',
                text: 'Some error occurred while submitting the records to SII',
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
              })
          }
        }
      });
  }

  renderCharts(dateFilter = false) {
    if (this.state.chartData.length > 0) {
      let param = "";

      if (
        dateFilter &&
        typeof this.state.endDateApi !== "undefined" &&
        typeof this.state.endDateApi !== "undefined"
      ) {
        param = `?dateFrom=${this.state.startDateApi}&dateTo=${this.state.endDateApi}`;
      }

      if (this.state.organisationID) {
        if (param == "") param = "?";
        else param += "&";
        param += "organisationID=" + this.state.organisationID;
      }

      let statusCode = 0;
      this.state.chartData.map(async value => {
        await fetch(`${API_URL}allSummaries/${value.type}${param}`, {
          method: "get",
          headers: {
            "Content-Type": "Application/json"
          },
          credentials: "include"
        })
          .then(res => {
            statusCode = res.status;
            return res.json();
          })
          .then(result => {
            console.log('result :: ', result);
            if (statusCode === 200) {
              if (value.name === "line") {
                this.setState({
                  displayChart: [
                    ...this.state.displayChart,
                    <Card small className="mb-4">
                      <Button
                        className="float-right mt-2 mr-2 position-absolute"
                        style={{ width: "100px", right: 0, zIndex: 9 }}
                        onClick={() => {
                          this.downloadFile(value.type);
                        }}
                      >
                        Download CSV
                      </Button>
                      <section>
                        <LineChart {...value.config} data={result} />
                      </section>
                    </Card>
                  ],
                  visible: false
                });
              } else if (value.name === "column") {
                this.setState({
                  displayChart: [
                    ...this.state.displayChart,
                    <Card small className="mb-4">
                      <Button
                        className="float-right mt-2 mr-2 position-absolute"
                        style={{ width: "100px", right: 0, zIndex: 9 }}
                        onClick={() => {
                          this.downloadFile(value.type);
                        }}
                      >
                        Download CSV
                      </Button>
                      <section>
                        <ColumnChart {...value.config} data={result} />
                      </section>
                    </Card>
                  ],
                  visible: false
                });
              } else if (value.name === "pie") {
                this.setState({
                  displayChart: [
                    ...this.state.displayChart,
                    <Card small className="mb-4">
                      <Button
                        className="float-right mt-2 mr-2 position-absolute"
                        style={{ width: "100px", right: 0, zIndex: 9 }}
                        onClick={() => {
                          this.downloadFile(value.type);
                        }}
                      >
                        Download CSV
                      </Button>
                      <section>
                        <PieChart {...value.config} data={result} />
                      </section>
                    </Card>
                  ],
                  visible: false
                });
              } else if (value.name === "sales_summary") {
                this.setState({
                  displayChart: [
                    ...this.state.displayChart,
                    <Card small className="mb-4">
                      <section>
                        <p
                          style={{
                            fontSize: "20px",
                            padding: "5px 10px",
                            paddingBottom: "0px",
                            marginBottom: "0px"
                          }}
                        >
                          {value.title}
                        </p>
                        <table
                          style={{
                            width: "50%",
                            margin: "10px"
                          }}
                        >
                          <tbody>
                            {result.map(value => {
                              return (
                                <tr>
                                  <th
                                    style={{
                                      border: "0px",
                                      fontSize: "14px",
                                      width: "40%",
                                      fontWeight: "600"
                                    }}
                                  >
                                    {value.label}
                                  </th>
                                  <td
                                    style={{ border: "0px", fontSize: "14px" }}
                                  >
                                    {value.value}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </section>
                    </Card>
                  ],
                  visible: false
                });
              } else if (value.name === "sii_summary") {
                this.setState({
                  displayChart: [
                    ...this.state.displayChart,
                    <Card small className="mb-4">
                      <section>
                        <p
                          style={{
                            fontSize: "20px",
                            padding: "5px 10px",
                            paddingBottom: "0px",
                            marginBottom: "0px"
                          }}
                        >
                          {value.title}
                        </p>

                        <table
                          style={{
                            width: "95%",
                            margin: "10px"
                          }}
                        >
                          <thead>
                            <tr>
                              {result.labels.map(value => {
                                return (
                                  <th
                                    className={
                                      value == "Monto EBOL" ||
                                        value == "Resubmit To Sii"
                                        ? "text-right"
                                        : "text-left"
                                    }
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {value}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody className="hoverTable">
                            {result.data.map(value => {
                              return (
                                <tr className={value.hasRemainingToSubmitRecords !== null && value.hasRemainingToSubmitRecords !== undefined && value.hasRemainingToSubmitRecords ? "tablepding tablebg" : "tablepding"}>
                                  <td
                                    onClick={(e) => this.handleDateButtonClicked(e, value, result.labelsOgData)}
                                  >{value.date}</td>
                                  <td>{value.ebolCount}</td>
                                  <td>{value.siiCount}</td>
                                  <td className="text-right">
                                    {value.ebolAmount}
                                  </td>
                                  <td className="text-right">
                                    {/* {value.siiAmount} */}
                                    {value.siiResubmitBtn !== undefined &&
                                      value.siiResubmitBtn !== null &&
                                      value.siiResubmitBtn.length > 0 &&
                                      value.siiResubmitBtn.indexOf("<") !== -1
                                      ? <span dangerouslySetInnerHTML={{ __html: value.siiResubmitBtn }} onClick={(e) => this.handleResubmitButtonClicked(e, value)}></span>
                                      : value.siiResubmitBtn
                                    }
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </section>
                    </Card>
                  ],
                  visible: false
                });
              }
            } else {
              console.log("failed");
              alert("You don't have a permission");
              this.setState({
                visible: false
              });
            }
          })
          .catch(err => {
            console.log(err);
            alert("Something went wrong");
            this.setState({
              visible: false
            });
          });
      });
    } else {
      this.setState({
        displayChart: (
          <>
            <img src={require("./../../images/500.png")} />
          </>
        )
      });
    }
  }

  handleChange(e) {
    this.setState(
      {
        apiEndPoint: e.target.value,
        visible: true,
        displayChart: []
      },
      () => this.getData()
    );
  }

  fillOrganisation() {
    fetch(`${API_URL}organisations`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          array.push({
            id: "",
            name: "All Organisation"
          });

          res.result.map(item => {
            let obj = item.data;

            array.push({
              id: item._id,
              name: item.data.name
            });
          });
          this.setState({
            organisationList: array
          });

          console.log(array);
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  }

  handleOrganisationChange = e => {
    console.log(e.value);
    this.setState(
      {
        organisationID: e.value,
        visible: true,
        displayChart: []
      },
      () => this.getData()
    );
  };

  handleCallback = (start, end) => {
    var startDate = new Date(start);
    var startDateApi = startDate.setDate(startDate.getDate() + 1);
    startDateApi = JSON.stringify(startDate);
    var endDate = new Date(end);
    var endDateApi = JSON.stringify(endDate);
    this.setState(
      {
        startDate: start,
        endDate: end,
        startDateApi,
        endDateApi,
        displayChart: [],
        visible: true
      },
      () => {
        this.renderCharts(true);
      }
    );
  };

  render() {
    return (
      <div>
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Charts"
              subtitle="Overview"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <>
                <Row>
                  <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                      <div className="row">
                        {/* <div className="col-sm-6"> */}
                        {/* <select
                          className="form-control"
                          onChange={e => this.handleOrganisationChange(e)}
                        >
                          {this.state.organisationList.map((e, key) => {
                            return <option value={e.id}>{e.name}</option>;
                          })}
                        </select> */}
                        <Col>
                          <OrganisationSearch
                            handleChange={this.handleOrganisationChange}
                            isFilter={true}
                          />
                        </Col>
                      </div>
                      {/* </div> */}
                    </Card>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                      <div className="row">
                        {/* <div className="col-sm-6"> */}
                        <select
                          className="form-control"
                          onChange={e => this.handleChange(e)}
                        >
                          <option value="billsummary">Billing</option>
                          <option value="invoices">Invoice</option>
                          <option value="creditnotes">Credit Note</option>
                          <option value="debitnotes">Debit Note</option>
                          <option value="deliverynotes">Delivery Note</option>
                        </select>
                      </div>
                      {/* </div> */}
                    </Card>
                  </Col>
                  <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                      <DateRangePicker
                        initialSettings={{
                          startDate: this.state.startDate,
                          endDate: this.state.endDate
                        }}
                        // onEvent={this.handleEvent}
                        onCallback={(start, end) =>
                          this.handleCallback(start, end)
                        }
                        locale={this.state.locale}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker>
                    </Card>
                  </Col>
                </Row>
                {this.state.visible ? (
                  <Card small className="mb-4 p-4">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.visible}
                      />
                    </div>
                  </Card>
                ) : (
                  this.state.displayChart
                )}
              </>
              {/* <Card small className="mb-4">
                <section>
                  <LineChart {...this.state.lineChartConfig} data={data} />
                </section>
              </Card>
              <Card small className="mb-4">
                <ColumnChart
                  {...this.state.columnChartConfig}
                  data={chartData}
                />
              </Card>
              <Card small className="mb-4">
                <PieChart {...this.state.pieChartConfig} data={pieData} />
              </Card> */}
            </Col>
          </Row>
          <Modal
            open={this.state.showSIIDatePopup}
            toggle={() => this.setState({ showSIIDatePopup: !this.state.showSIIDatePopup })}
            centered={true}
            className="siimodal"
            size="lg"
          >
            <ModalHeader>{`${this.state.siiSelectedDate} SII Resubmit Records for ${this.state.selectedSIIDocumentName}`}</ModalHeader>
            <ModalBody>
              <table
                className="siiRecordsTable"
                style={{
                  width: "95%",
                  margin: "10px",
                  borderSpacing: '5px 5px'
                }}
              >
                <thead>
                  <tr>
                    {this.state.siiRecordsPopupLabels.map(value => {
                      return (
                        <th
                          className={
                            "text-left siiRecordsTh"
                          }
                          style={{ fontWeight: "bold" }}
                        >
                          {value}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedSIIData.map(value => {
                    return (
                      <tr className="tablepding">
                        {Object.entries(value).map(([key, data]) => {
                          console.log('data', data)
                          if (key === "transactionIdList") {
                            return <td className="text-left siiRecordsTd" style={{ width: 100 }}>

                              <ul>
                                {data.map((value, index) => {
                                  return (
                                    <li>
                                      {value}
                                    </li>
                                  )
                                })}
                              </ul>
                            </td>
                          } else {
                            return (
                              <td className="text-left siiRecordsTd" style={{ width: 100 }}>
                                <p style={{ wordWrap: 'anywhere' }}>
                                  {data}
                                </p>
                              </td>
                            )
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              <Button
                // className="float-right mt-2 mr-2 position-absolute"
                // style={{ width: "100px", right: 0, zIndex: 9 }}
                onClick={() => {
                  this.setState({
                    showSIIDatePopup: false
                  })
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    );
  }
}
