import React from 'react'
import { Button, Container, Card } from "shards-react";
import { Row, Col, Form, FormGroup, InputGroup, CardBody } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import { API_URL } from "../config";
import ReactDatatable from "@ashvin27/react-datatable";
import OrganisationSearch from "./../components/organisation-search";
import Loader from "react-loader-spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

export const MarkAsRead = (props) => {
    const [btnLoading, setBtnLoading] = React.useState(false)
    const updateStatus = () => {
        setBtnLoading(true)
        let bodyStrings = JSON.stringify({
            status: 'SUBMITTED'
        });
        let statusCode = "";
        console.log(`${API_URL}intercambioDocuments/${props.id}`)
        fetch(`${API_URL}intercambioDocuments/${props.id}`, {
            method: "put",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: bodyStrings
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log(res)
                setBtnLoading(false)
                if (statusCode === 200) {
                    props.fetchData()
                }
            })
            .catch(err => {
                console.log(err);
                setBtnLoading(false)
            });

    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', }}>
            <Button
                // disabled={{}}
                theme="success"
                // style={{ height: 40, }}
                className="mb-2 mr-1"
                onClick={() => {
                    // updateStatus()
                }}
            >
                Mark as submited
            </Button>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 5
                }}
            >
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={40}
                    width={40}
                    visible={btnLoading}
                />
            </div>
        </div>
    )
}

const PurchaseDocuments = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let start_date = mm + "/" + "01" + "/" + yyyy;
    let end_date = mm + "/" + dd + "/" + yyyy;
    const [startDate, setStartDate] = React.useState(start_date)
    const [endDate, setEndDate] = React.useState(end_date)
    const [startDateApi, setStartDateApi] = React.useState("")
    const [endDateApi, setEndDateApi] = React.useState("")
    const [locale, setLocale] = React.useState({
        format: "DD/MM/YYYY"
    })
    const [organisationList, setorganisationList] = React.useState([]);
    const [organizationId, setOrganitationId] = React.useState('');
    const [documentType, setDocumentType] = React.useState("ACCEPTED")
    const [records, setRecords] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [checked, setChecked] = React.useState([]);
    const [allDocumentsChecked, setAllDocumentsChecked] = React.useState(false)
    const [isArchiveButtonDesabled, setIsArchiveButtonDesabled] = React.useState(true)
    console.log("Check box values....", checked)


    React.useEffect(() => {
        let result = checked.find(item => {
            if (item) return item
        });
        if (result) {
            setIsArchiveButtonDesabled(false)
        } else (
            setIsArchiveButtonDesabled(true)
        )

    }, [checked])
    const columns = [
        documentType !== 'ARCHIVED' && records.length>0 &&{
            key: "",
            text: <input type="checkbox"
                defaultChecked={allDocumentsChecked}
                onChange={() => {
                    setChecked([])

                    if (allDocumentsChecked) {
                        setChecked(new Array(records.length).fill(false));
                        setAllDocumentsChecked(false)
                    } else {
                        setChecked(new Array(records.length).fill(true));
                        setAllDocumentsChecked(true)
                    }
                }}
            />,
            // text: '',

            cell: (record, index) => {
                return (
                    <input type="checkbox"
                    checked={checked[index]}
                        onChange={() => {
                            const updatedCheckedState = checked.map((item, checkIndex) =>
                                checkIndex === index ? !item : item
                            );
                            setChecked(updatedCheckedState);
                        }}
                    />
                )
            }
        },
        {
            key: "ackDate",
            text: "Fecha Acuse Recibo"
        },
        {
            key: "rutReceptor",
            text: "RUT Receptor"
        },
        {
            key: "rutEmisor",
            text: "RUT Emisor"
        },
        {
            key: "tpoDoc",
            text: "Tipo De Documento"
        },
        {
            key: "folio",
            text: "Folio"
        },
        {
            key: "mntTotal",
            text: "Monto Total"
        },
        {
            key: "documentStatus",
            text: "Aceptado O Rechazado",
            // cell: (record) => {
            //     return (
            //         <>
            //             <div style={{}}>
            //                 {record.documentStatus}

            //             </div>
            //             {/* {record.userStatus === 'REJECTED' &&
            //                 <div style={{}}>
            //                     {record.rejectReason}

            //                 </div>
            //             } */}
            //         </>
            //     );
            // }
        },
        {
            key: "_id",
            text: "Action",
            className: "action",
            cell: (record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            theme="primary"
                            // style={{ height: 40 }}
                            className="mb-2 mr-2"
                            onClick={() =>
                                window.open(record.urlInfo.url, "_blank")}
                        >
                            {record.urlInfo.text}
                        </Button>
                        {/* 
                        <MarkAsRead record={record} /> */}
                    </div>
                );
            }
        }
    ];
    const config = {
        page_size: 10,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    const fillOrganisation = () => {
        fetch(`${API_URL}organisations`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                if (res.result.length > 0) {
                    let array = [];

                    array.push({
                        id: "",
                        name: "All Organisation"
                    });

                    res.result.map(item => {
                        let obj = item.data;

                        array.push({
                            id: item._id,
                            rutNumber: item.data.rutNumber,
                            name: item.data.name
                        });
                    });

                    setorganisationList(array);
                    console.log(array);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    React.useEffect(() => fillOrganisation(), []);

    const handleCallback = (start, end) => {
        var startDate = new Date(start);
        var startDateApi = startDate.setDate(startDate.getDate() + 1);
        startDateApi = startDate.toISOString().slice(0, 10);;
        var endDate = new Date(end);
        var endDateApi = endDate.toISOString().slice(0, 10);;
        setStartDate(start)
        setEndDate(end)
        setStartDateApi(startDateApi)
        setEndDateApi(endDateApi)
    };
    const fetchData = () => {
        let url = `${API_URL}allPurchaseDocuments?status=${documentType}&organisationID=${organizationId}&fromDate=${startDateApi}&toDate=${endDateApi}`
        setLoading(true);
        let statusCode = "";
        console.log(url);
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log(res);
                setLoading(false)
                if (statusCode === 200) {
                    setRecords(res.result);
                    setChecked(new Array(res.result.length).fill(false));
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }

    const handleOrganisationChange = e => {
        let orgID = e.value;
        console.log("organisation Id is...", orgID);
        setOrganitationId(orgID);
    };

    const handleChange = (e) => {
        console.log('values....', e.target.value)
        setDocumentType(e.target.value)
        setChecked([])
        setAllDocumentsChecked(false)
    }

    React.useEffect(() => {
        fetchData()
    }, [organizationId, documentType, startDateApi, endDateApi])


    const onArchiveDocuments = () => {
        let selectedDocuments = []
        checked.map((item, index) => {
            if (item) {
                //   console.log(records[index])
                selectedDocuments.push(records[index]._id)
            }
        })
        console.log("Selected docuents id....", selectedDocuments)
        let bodyStrings = JSON.stringify(selectedDocuments);
        let statusCode = "";
        let url = `${API_URL}allPurchaseDocuments`
        console.log(url)
        fetch(url, {
            method: "put",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: bodyStrings
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log("API response....", res)
                if (statusCode === 200) {
                    fetchData()
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    const downloadFile = async () => {
        let url = `${API_URL}allPurchaseDocuments?status=${documentType}&organisationID=${organizationId}&type=csv&fromDate=${startDateApi}&toDate=${endDateApi}`
        console.log(url);
        fetch(url,
            {
                method: "get",
                headers: {
                    "Content-Type": "Application/json"
                },
                credentials: "include"
            }
        )
            .then(res => res.json())
            .then(data => {
                let csvContent =
                    "data:text/csv;charset=utf-8," +
                    data.result.map(e => e).join("\n");
                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "ebol_purchase_documents.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Container
            fluid
            className="main-content-container px-4"

            style={{ marginBottom: "200px" }}
        >
            <Row noGutters className="page-header py-4">
                <PageTitle title="Intercambio Documents" className="text-sm-left mb-3" />
            </Row>

            <Row>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <Col>
                                <OrganisationSearch
                                    handleChange={handleOrganisationChange}
                                    isFilter={true}
                                />
                            </Col>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <select value={documentType}
                                className="form-control"
                                onChange={e => handleChange(e)}
                            >
                                <option value="ACCEPTED">Aceptado</option>
                                <option value="REJECTED">Rechazado</option>
                                <option value="RECEIVED">Recibida</option>
                                <option value="ARCHIVED">Archived</option>

                            </select>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <DateRangePicker
                                initialSettings={{
                                    startDate: startDate,
                                    endDate: endDate
                                }}
                                // onEvent={this.handleEvent}
                                onCallback={(start, end) =>
                                    handleCallback(start, end)
                                }
                                locale={locale}
                            >
                                <input type="text" className="form-control" />
                            </DateRangePicker>
                        </div>
                    </Card>
                </Col>
            </Row>


            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <Button
                                theme="primary"
                                className="mb-2 float-right"
                                style={{ marginLeft: 10 }}
                                onClick={downloadFile}
                            >
                                Download CSV
                            </Button>


                            <Button theme="primary" className="mb-2 float-left"
                                disabled={isArchiveButtonDesabled}
                                onClick={() => onArchiveDocuments()}
                            >
                                Archive
                            </Button>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    visible={loading}
                                />
                            </div>
                            <div className="MainDiv">
                                <div className="table-responsive">
                                    {!loading && <ReactDatatable
                                        config={config}
                                        records={records}
                                        columns={columns}
                                        loading={loading}
                                    />}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </Container>
    )
}

export default PurchaseDocuments


