import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    overview: "OVERVIEW",
    create_customer: "Create User",
    f_name: "Full Name",
    email: "Email",
    address: "Address",
    phone: "Phone number",
    org_name: "Organisation Name",
    user_code: "User Code",
    password: "Password",
    fill_fields: "Fill All Fields !",
    go_back: "Go Back",
    create: "Create User",
    edit: "Edit User",
    select_user_role: "Select user role",
  },
  es: {
    overview: "OVERVIEW es",
    create_customer: "Create User es",
    f_name: "Full Name es",
    email: "Email es",
    address: "Dirección",
    phone: "Phone number es",
    org_name: "Organisation Name es",
    user_code: "User Code es",
    password: "Password es",
    fill_fields: "Fill All Fields ! es",
    go_back: "Go Back es",
    create: "Create User es",
    edit: "Edit User es",
    select_user_role: "Seleccione rol de usuario",
  }
});
