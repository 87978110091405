import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    overview: "OVERVIEW",
    edit_profile: "Edit Profile",
    f_name: "Full Name",
    email: "Email",
    password: "Password",
    fill_fields: "Fill All Fields !",
    update_profile: "Update Profile",
    change_password: "Change Password",
    current_password: "Current Password",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    update_password: "Update Password",
    password_not_match: "New password and confirm password not match",
    password_success: "Password changed successfully",
    profile_updated: "Profile updated successfully"
  },
  es: {
    overview: "OVERVIEW ES",
    edit_profile: "Edit Profile ES",
    f_name: "Full Name ES",
    email: "Email ES",
    password: "Password ES",
    fill_fields: "Fill All Fields ! ES",
    update_profile: "Update Profile ES",
    change_password: "Change Password ES",
    current_password: "Current Password ES",
    new_password: "New Password ES",
    confirm_password: "Confirm Password ES",
    update_password: "Update Password ES",
    password_not_match: "New password and confirm password not match ES",
    password_success: "Password changed successfully ES",
    profile_updated: "Profile updated successfully ES"
  }
});
