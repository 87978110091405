import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import SearchFilter from "./../search_filter";
import { strings } from "./../../localization";
import { Language, API_URL, LIMIT } from "./../../config";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  FormInput
} from "shards-react";
// import $ from "jquery";
import PageTitle from "../../components/common/PageTitle";
import OrganisationSearch from "./../../components/organisation-search";
import swal from "sweetalert2";
window.Swal = swal;
const filters = [
  {
    name: "Title"
  },
  {
    name: "Rut Emissor"
  },
  {
    name: "Rut Emissor Name"
  },
  {
    name: "Type Of Document"
  },
  {
    name: "Consumed"
  },
  {
    name: "Folio Start"
  },
  {
    name: "Folio End"
  },
  {
    name: "Folio Date"
  }
];
class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    permissions: {},
    prev: true,
    loading: true,
    next: false,

    organisation_id: ""
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "title",
        text: "Title"
      },
      {
        key: "rutEmissor",
        text: "Rut Emissor"
      },
      {
        key: "rutEmissorName",
        text: "Rut Emissor Name"
      },
      {
        key: "typeOfDocument",
        text: "Type Of Document"
      },
      {
        key: "consumed",
        text: "Consumed"
      },
      {
        key: "folioStart",
        text: "Folio Start"
      },
      {
        key: "folioEnd",
        text: "Folio End"
      },
      {
        key: "folioDate",
        text: "Folio Date"
      },
      {
        key: "action",
        text: strings.action,
        className: "action",
        cell: record => {
          return (
            //   /create-customer
            <div className="mt-2">
              <Link
                to={{
                  pathname: "/manage-folio/edit/" + record.id
                }}
              >
                <Button theme="success" className="mb-2 mr-1">
                  {strings.edit}
                </Button>
              </Link>
              <Button
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deleteRecord(record.id)}
              >
                {strings.delete}
              </Button>
            </div>
          );
        }
      }
    ];
    this.config = {
      page_size: 10,
      show_length_menu: false,
      show_info: false,
      show_pagination: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }

  deleteRecord(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: "You want to delete this folio ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(`${API_URL}folios/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();
                swal.fire(strings.deleted, strings.success_deleted, "success");
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => {});
  }

  async componentDidMount() {
    this.getData();

    let user_info = localStorage.getItem("user_info");
    user_info = JSON.parse(user_info);

    fetch(`${API_URL}permissions?userid=${user_info._id}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          permissions: data.data.permissions
        });
      });

    // let permissions = localStorage.getItem("user_permissions");

    // console.log(permissions);
    // permissions =  JSON.parse(permissions);

    // console.log(permissions['all_folios']);

    // this.setState({
    //   permissions : permissions
    // })

    // user_info = JSON.parse(user_info);

    // alert(user_info._id)
    // let id = user_info._id;

    // fetch(`${API_URL}permissions?userid=${id}`, {
    //   method: "GET",
    //   credentials: "include"
    // })
    //   .then(res => res.json())
    //   .then(data => {
    //     // let permissions = data.data.permissions;
    //     console.log(data);
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
  }

  getData = (skip = 0, params = "") => {
    this.setState({
      loading: true
    });

    fetch(`${API_URL}folios?limit=${this.state.limit}&skip=${skip}${params}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];
          res.result.map(item => {
            let obj = item.data;
            obj = Object.assign({ id: item._id }, obj);
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }
          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  downloadFile = async () => {
    console.log("search param in button", localStorage.getItem("Myparams"));
    console.log("orgid param in button", this.state.organisation_id);
    let searchPrams = await localStorage.getItem("Myparams");
    let organisation_id1 = this.state.organisation_id;

    console.log(
      `${API_URL}foliosDownload?${searchPrams}&f_organisationID=${this.state.organisation_id}`
    );
    fetch(
      `${API_URL}foliosDownload?${searchPrams}&f_organisationID=${organisation_id1}`,
      {
        method: "get",
        headers: {
          "Content-Type": "Application/json"
        },
        credentials: "include"
      }
    )
      .then(res => res.json())
      .then(data => {
       console.log('Download csv file api res',data )
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map(e => e.join(",")).join("\n");
          // console.log('Download csv file ',csvContent )

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ebol_invoices.csv");
        document.body.appendChild(link);

        link.click(); // This will download the data file named "my_data.csv".

        localStorage.setItem("Myparams", "");
        this.setState({ organisation_id: "" });
      })
      .catch(err => {
        console.log(err);
      });
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  handleChange = selectedOption => {
    if (selectedOption.value) {
      let param = `&filter=true&f_organisationID=${selectedOption.value}`;

      this.getData(0, param);
    } else {
      this.getData();
    }

    this.setState({
      organisation_id: selectedOption.value,
      org_code: selectedOption,
      orgcode: selectedOption.value,
      loading: true
    });
  };

  render() {
    //Datatable HTML

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {/* <a onClick={() => this.deleteThisGoal()} className="btn btn-danger">
          Open alert
        </a>
        {this.state.alert} */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Folios"
            subtitle={strings.overview}
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <Row>
                  <Col>
                    <SearchFilter getData={this.getData} filters={filters} />
                  </Col>
                  {this.state.permissions["all_folios"] === true && (
                    <Col>
                      <OrganisationSearch
                        handleChange={this.handleChange}
                        isFilter={true}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <Button
                  theme="primary"
                  className="mb-2 float-right"
                  style={{ marginLeft: 10 }}
                  onClick={this.downloadFile}
                >
                  Download CSV
                </Button>

                <Link to="/manage-folio/create">
                  <Button theme="primary" className="mb-2 float-right">
                    <i class="material-icons">card_travel</i> Create Folio
                  </Button>
                </Link>

                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />
                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                 
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Customers;
