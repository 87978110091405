import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
  en: {
    login: "Sign In",
    password: "Password",
    have_code: "I have a reset code.",
    forgot_password: "Forgot Password?",
    required_usercode: "Username required",
    required_orgcode: "Org code required",
    required_password: "Password is required",
    enter_code: "Enter verification code",
    back_login: "Back to login",
    email: "Email address",
    enter_email: "Enter email",
    enter_password: "Enter password",
    invalid_code: "Code is incorrect.",
    remember: "Remember me",
    forgot_password_btn: "Send Reset Code",
    required_email: "Email is required",
    invalid_email: "Email is invalid",
    new_password: "New Password",
    confirm_password: "Confirm Password",
    reset_password_btn: "Reset Password",
    passwords_not_match: "New Password & Confirm Password Must be same",
    send_success: "Verification code successfully sent on ",
    fill_fields: "Fill All Fields !",
    verify_code: "Verify OTP",
    change_password: "Change Password",
    password_success: "Password changed successfully",
    usercode: "Username",
    orgcode: "Org Code"
  },
  es: {
    login: "Sign In es",
    password: "Password es",
    have_code: "I have a reset code. es",
    forgot_password: "Forgot Password? es",
    required_usercode: "Username required es",
    required_orgcode: "Org code required es",
    required_password: "Password is required es",
    enter_code: "Enter verification code es",
    back_login: "Back to login es",
    email: "Email address es",
    enter_email: "Enter email es",
    enter_password: "Enter password es",
    invalid_code: "Code is incorrect. es",
    remember: "Remember me es",
    forgot_password_btn: "Send Reset Code es",
    required_email: "Email is required es",
    invalid_email: "Email is invalid es",
    new_password: "New Password es",
    confirm_password: "Confirm Password es",
    reset_password_btn: "Reset Password es",
    passwords_not_match: "New Password & Confirm Password Must be same es",
    send_success: "Verification code successfully sent on  es",
    fill_fields: "Fill All Fields ! es",
    verify_code: "Verify OTP es",
    change_password: "Change Password es",
    password_success: "Password changed successfully es",
    usercode: "Username es",
    orgcode: "Org Code es"
  }
});
