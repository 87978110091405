import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  FormInput,
  func
} from "shards-react";
// import $ from "jquery";
import PageTitle from "../components/common/PageTitle";
import { strings } from "./../localization/Inquire";
import { Language } from "./../config";

// import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert2";
import { type } from "jquery";
window.Swal = swal;

class App extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "name",
        text: strings.f_name,
        className: "name",
        align: "left",
        sortable: true
      },
      {
        key: "email",
        text: strings.email,
        className: "email",
        align: "left",
        sortable: true
      },
      {
        key: "phone",
        text: strings.phone,
        className: "phone",
        sortable: true
      },
      {
        key: "org_name",
        text: strings.org_name,
        className: "org_name",
        align: "left",
        sortable: true
      },
      {
        key: "action",
        text: strings.action,
        className: "action",
        align: "left",
        sortable: false,
        cell: record => {
          return (
            <div className="mt-2">
              <Link to={"/manage-customer/accept/" + record.id}>
                <Button size="sm" theme="success" className="mb-2 mr-1">
                  {strings.accept}
                </Button>
              </Link>
              <Button
                size="sm"
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.rejectRequest(record.id)}
              >
                {strings.reject}
              </Button>
            </div>
          );
        }
      }
    ];
    this.config = {
      page_size: 10,
      show_length_menu: false,
      button: {
        excel: false,
        print: false,
        extra: false
      }
    };
  }

  rejectRequest(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: strings.confirm_reject,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: strings.yes_button
      })
      .then(result => {
        fetch("rejectRequest", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "X-language": Language
          },
          body: JSON.stringify({ id })
        })
          .then(res => res.json())
          .then(res => {
            if (result.value) {
              swal.fire(strings.rejected, strings.success_rejected, "success");
            }
          });
      });
  }

  componentDidMount() {
    this.getData();
  }

  getData = (queryString = "") => {
    // alert(queryString);
    let url = "/api/user-requests/?" + queryString;
    fetch(url, {
      headers: {
        Accept: "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        // console.log(res.);
        this.setState({
          total: res.total,
          records: res.result
        });
      });
  };

  tableChangeHandler = data => {
    let queryString = Object.keys(data)
      .map(key => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    // alert(queryString);
    this.getData(queryString);
  };

  render() {
    //Datatable HTML

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {/* <a onClick={() => this.deleteThisGoal()} className="btn btn-danger">
          Open alert
        </a>
        {this.state.alert} */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={strings.inquire_request}
            subtitle={strings.overview}
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <FormInput
                  type="search"
                  id="myInputTextField"
                  placeholder={strings.search_placeholder}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <div className="MainDiv">
                  <div className="container">
                    {/* <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      extraButtons={this.extraButtons}
                    /> */}

                    <div className="table-responsive">
                      <ReactDatatable
                        config={this.config}
                        records={this.state.records}
                        columns={this.columns}
                        dynamic={true}
                        total_record={this.state.total}
                        onChange={this.tableChangeHandler}
                      />
                    </div>
                    {/* <table className="table display">
                      <thead className="bg-light">
                        <tr>
                          <th>Full name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Organization Name</th>
                          <th className="no-sort">Action</th>
                        </tr>
                      </thead>
                      <tbody> */}
                    {/* {this.state.records.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.f_name}</td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>
                              <td>{item.org_name}</td>
                              <td>
                                <div className="mt-2">
                                  <Button
                                    size="sm"
                                    theme="success"
                                    className="mb-2 mr-1"
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    size="sm"
                                    theme="danger"
                                    className="mb-2 mr-1"
                                  >
                                    Reject
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })} */}
                    {/* </tbody> */}
                    {/* </table> */}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
      </Container>
    );
  }
}
export default App;
