import React from "react";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import SearchFilter from "./../search_filter";

import { strings } from "./../../localization/Inquire";
import { Language, API_URL, LIMIT } from "./../../config";
import { Container, Row, Col, Card, Button, CardBody } from "shards-react";
// import $ from "jquery";
import PageTitle from "./../../components/common/PageTitle";
// import SweetAlert from "react-bootstrap-sweetalert";
import swal from "sweetalert2";
window.Swal = swal;
const filters = [
  {
    name: "Rut Number"
  },
  {
    name: "Organisation Code"
  },
  {
    name: "Name"
  },
  {
    name: "City"
  },
  {
    name: "State"
  },
  {
    name: "Giro Codes"
  }
];
class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    loading: true,
    next: false
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: 'companyLogo',
        text: 'Logo',
        cell: record => {
          console.log('record', record);
          return (
            <div
             style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
             }}
            >
            {record.companyLogo !== undefined && record.companyLogo !== ''
              &&  <img
                      id="main-logo"
                      className="align-top"
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                      onClick={() => {
                        var image = new Image();
                        image.src = record.companyLogo;
                        var w = window.open("");
                        w.document.write(image.outerHTML);
                      }}
                      src={record.companyLogo}
                      alt="Company Logo"
                />
            }
            </div>
          )
        }
      },
      {
        key: "rutNumber",
        text: "RUT Number"
      },
      {
        key: "organisationCode",
        text: "Organisation Code"
      },
      {
        key: "name",
        text: "Name"
      },
      {
        key: "city",
        text: "City"
      },
      {
        key: "state",
        text: "State"
      },
      {
        key: "giroCodes",
        text: "Giro Codes"
      },
      {
        key: "action",
        text: strings.action,
        sortable: false,
        cell: record => {
          return (
            //   /create-customer
            <div className="mt-2">
              <Link
                to={{
                  pathname: "/manage-org/" + record.id,
                  state: {
                    type: "edit",
                    name: record.name,
                    code: record.code,
                    price: record.price,
                    stock: record.stock
                  }
                }}
              >
                <Button size="sm" theme="success" className="mb-2 mr-1">
                  {strings.edit}
                </Button>
              </Link>
              <Button
                size="sm"
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deleteItem(record.id)}
              >
                {strings.delete}
              </Button>
            </div>
          );
        }
      }
    ];
    this.config = {
      // page_size: this.state.limit - 1,
      // length_menu: [10, 20, 50],
      // show_length_menu: false,
      show_pagination: false,
      show_info: false

      // pagination: "advance",
    };
  }

  // editRecord(record) {
  //   console.log("Edit Record", record);
  // }

  deleteItem(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: strings.delete_item,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: login => {
          return fetch(`${API_URL}organisations/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();

                swal.fire(
                  strings.deleted,
                  "Organisation deleted successfully.",
                  "success"
                );
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => {});
  }

  componentDidMount() {
    this.getData();
  }

  getData = (skip = 0, params = "") => {
    // alert(queryString);
    this.setState({
      loading: true
    });

    fetch(
      `${API_URL}organisations?limit=${this.state.limit}&skip=${skip}${params}`,
      {
        method: "GET",
        credentials: "include"
      }
    )
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          res.result.map(item => {
            let obj = item.data;
            obj = Object.assign({ id: item._id }, obj);
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  render() {
    //Datatable HTML

    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        {/* <a onClick={() => this.deleteThisGoal()} className="btn btn-danger">
          Open alert
        </a>
        {this.state.alert} */}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Organisation"
            subtitle="overview"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <SearchFilter getData={this.getData} filters={filters} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <Link to="manage-org">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 float-right"
                  >
                    <i class="material-icons">add</i> Create Organisation
                  </Button>
                </Link>
                <div className="MainDiv">
                  <div className="table-responsive">
                    <ReactDatatable
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      loading={this.state.loading}
                    />

                    <nav className="d-flex justify-content-center align-items-center">
                      <ul className="pagination">
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageDecrement()}
                            style={
                              this.state.prev
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:;"
                            onClick={() => this.pageIncrement()}
                            style={
                              this.state.next
                                ? { cursor: "not-allowed", color: "gray" }
                                : null
                            }
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
      </Container>
    );
  }
}
export default Customers;
