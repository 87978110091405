import React, { useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  CardHeader
} from "shards-react";
// import PageTitle from "../components/common/PageTitle";
// import PasswordStrengthBar from "react-password-strength-bar";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./Style.module.scss";
import $ from "jquery";
import { strings } from "./../localization";
import { API_URL, Language } from "./../config";
class PasswordField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: true
    };
  }

  hideSwitch = ev => {
    this.setState({ hide: !this.state.hide });
  };

  render() {
    const { title, name } = this.props;
    const { hide } = this.state;
    return (
      <Row form>
        <Col lg="12" className="form-group">
          <label>{title} </label>
          <FormInput
            type={hide ? "password" : "input"}
            id={name}
            placeholder={title}
          />
          <span className={styles.password__show} onClick={this.hideSwitch}>
            {hide ? (
              <i className="material-icons" style={{ top: "-4px" }}>
                visibility
              </i>
            ) : (
              <i className="material-icons" style={{ top: "-4px" }}>
                visibility_off
              </i>
            )}
          </span>
        </Col>
      </Row>
    );
  }
}

class ChangeUserPassword extends React.Component {
  state = {
    loader: "",
    button: false
  };

  constructor(props) {
    super(props);
    strings.setLanguage(Language);
  }

  render() {
    const { button, loader } = this.state;

    const handleClick = () => {
      const oldPassword = $("#o_password").val();
      const newPassword = $("#n_password").val();
      const confirmPassword = $("#c_password").val();

      if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        if (newPassword !== confirmPassword) {
          toast.error(strings.password_not_match, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          this.setState({
            button: true,
            loader: "fa fa-spinner fa-spin"
          });

          let statusCode = "";

          fetch(`${API_URL}changePassword`, {
            method: "put",
            body: JSON.stringify({
              password: oldPassword,
              newPassword: newPassword
            }),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            credentials: "include"
          })
            .then(res => {
              statusCode = res.status;
              return res.json();
            })
            .then(res => {
              // console.log(res.message);
              if (res.message) {
                if (statusCode === 200) {
                  toast.success(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                  });
                } else {
                  toast.error(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                  });
                }
                $("#o_password").val("");
                $("#n_password").val("");
                $("#c_password").val("");
                this.setState({
                  button: false,
                  loader: ""
                });
              }
            });
        }
      }
    };

    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{strings.change_password}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form id="form">
                  <PasswordField
                    title={strings.current_password}
                    name="o_password"
                  />

                  <PasswordField
                    title={strings.new_password}
                    name="n_password"
                  />

                  {/* <PasswordStrengthBar password="123" /> */}

                  <PasswordField
                    title={strings.confirm_password}
                    name="c_password"
                  />

                  <ToastContainer />
                  <Button
                    theme="accent"
                    onClick={() => handleClick()}
                    disabled={button}
                  >
                    <i className={loader} style={{ fontSize: "15px" }}></i>{" "}
                    {strings.update_password}
                  </Button>
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
      // </Col>
      //   </Row>
      // </Container>
    );
  }
}

export default ChangeUserPassword;
