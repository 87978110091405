export default function() {
  return [
    {
      title: "Dashboard",
      title_es: "Dashboard ES",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Inquire Request",
      title_es: "Inquire Request ES",
      htmlBefore: '<i class="material-icons">dns</i>',
      to: "/user-request"
    },
    {
      title: "Organisations",
      title_es: "Organisations ES",
      htmlBefore: '<i class="material-icons">business</i>',
      to: "/organisations"
    },
    // {
    //   title: "Create User",
    //   title_es: "Create User ES",
    //   htmlBefore: '<i class="material-icons">person_add</i>',
    //   to: "/manage-customer/create"
    // },
    {
      title: "Folios",
      title_es: "Folios Es",
      htmlBefore: '<i class="material-icons">card_travel</i>',
      to: "/folios"
    },
    {
      title: "Folio Consumptions",
      title_es: "Folios Consumptions Es",
      htmlBefore: '<i class="material-icons">card_travel</i>',
      to: "/folio-consumptions"
    },
    {
      title: "Users",
      title_es: "Users Es",
      htmlBefore: '<i class="material-icons">group</i>',
      to: "/customers"
    },
    {
      title: "Reports",
      title_es: "Reports Es",
      htmlBefore: '<i class="material-icons">analytics</i>',
      to: "/charts"
    },
    // {
    //   title: "Blog Posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts"
    // },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post"
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview"
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables"
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite"
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors"
    // },
    {
      title: "Edit Profile",
      title_es: "Edit Profile ES",
      htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
      to: "/edit-user-profile"
    },
    {
      title: "Settings",
      title_es: "Settings ES",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/settings"
    },
    {
      title: "Cert Process",
      title_es: "Cert Process",
      htmlBefore: '<i class="material-icons">settings</i>',
      to: "/CertProcess"
    },
    // {
    //   title: "Intercambio Documents",
    //   title_es: "Intercambio Documents",
    //   htmlBefore: '<i class="material-icons">topic</i>',
    //   to: "/IntercambioDocuments"
    // },
    {
      title: "Purchase Documents",
      title_es: "Purchase Documents",
      htmlBefore: '<i class="material-icons">topic</i>',
      to: "/PurchaseDocuments"
    },
    {
      title: "Intercambio Ventas",
      title_es: "Intercambio Ventas",
      htmlBefore: '<i class="material-icons">topic</i>',
      to: "/IntercambioVentas"
    },
    {
      title: "Logout",
      title_es: "Logout ES",
      htmlBefore: '<i class="material-icons" >&#xE879;</i>',
      to: "/logout"
    }
  ];
}

// export default function() {
//   return [
//     {
//       title: "Dashboard",
//       to: "/dashboard",
//       htmlBefore: '<i class="material-icons">dashboard</i>',
//       htmlAfter: ""
//     },
//     {
//       title: "Inquire Request",
//       htmlBefore: '<i class="material-icons">analytics</i>',
//       to: "/user-request"
//     },
//     {
//       title: "Create Customer",
//       htmlBefore: '<i class="material-icons">person_add</i>',
//       to: "/manage-customer/create"
//     },
//     {
//       title: "Customers",
//       htmlBefore: '<i class="material-icons">group</i>',
//       to: "/customers"
//     },

//     // {
//     //   title: "Blog Posts",
//     //   htmlBefore: '<i class="material-icons">vertical_split</i>',
//     //   to: "/blog-posts"
//     // },
//     // {
//     //   title: "Add New Post",
//     //   htmlBefore: '<i class="material-icons">note_add</i>',
//     //   to: "/add-new-post"
//     // },
//     // {
//     //   title: "Forms & Components",
//     //   htmlBefore: '<i class="material-icons">view_module</i>',
//     //   to: "/components-overview"
//     // },
//     // {
//     //   title: "Tables",
//     //   htmlBefore: '<i class="material-icons">table_chart</i>',
//     //   to: "/tables"
//     // },
//     // {
//     //   title: "User Profile",
//     //   htmlBefore: '<i class="material-icons">person</i>',
//     //   to: "/user-profile-lite"
//     // },
//     // {
//     //   title: "Errors",
//     //   htmlBefore: '<i class="material-icons">error</i>',
//     //   to: "/errors"
//     // },
//     {
//       title: "Edit Profile",
//       htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
//       to: "/edit-user-profile"
//     },
//     {
//       title: "Settings",
//       htmlBefore: '<i class="material-icons">settings</i>',
//       to: "/settings"
//     },
//     {
//       title: "Logout",
//       htmlBefore: '<i class="material-icons" >&#xE879;</i>',
//       to: "/logout"
//     }
//   ];
// }
