import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import CustomLayout from "./layouts/Custom";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import ManageUserPermissions from "./views/users/permissions";
import Tables from "./views/Tables";
// import BlogPosts from "./views/BlogPosts";
import Login from "./views/Login";
import EditProfile from "./views/EditProfile";
import UserRequest from "./views/UserRequest";
import ManageUsers from "./views/users/manage";
import Settings from "./views/settings";
import Users from "./views/users";
import Charts from "./views/charts";
import CertProcess from "./views/CertProcess";
import IntercambioDocuments from './views/IntercambioDocuments'
import PurchaseDocuments from './views/PurchaseDocuments'
import IntercambioVentas from "./views/IntercambioVentas"
import Organisations from "./views/organisations/Index";
import ManageOrganisations from "./views/organisations/ManageOrg";
import Folios from "./views/folios";
import ManageFolios from "./views/folios/manage";
import FolioConsumptions from "./views/folio_consumptions";
// import ManageFolioConsumption from "./views/folio_consumptions/manage";
import { Session } from "bc-react-session";

import Cookies from "universal-cookie";
const cookies = new Cookies();

// let loggedIn = localStorage.getItem("login_info");
// let loggedIn = cookies.get("login_info");
let session = Session.get();
let loggedIn = session.isValid;

// alert(loggedIn);

// loggedIn = true;

const logout = () => {
  // cookies.remove("login_info");
  Session.destroy();

  // localStorage.removeItem("login_info");
  // return true;
  // () => ;
  // alert(res);
  window.location = "/";
};

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: () => (loggedIn ? <BlogOverview /> : <Redirect to="/login" />)
  },
  // {
  //   path: "/user-profile-lite",
  //   layout: DefaultLayout,
  //   component: UserProfileLite
  // },
  // {
  //   path: "/add-new-post",
  //   layout: DefaultLayout,
  //   component: AddNewPost
  // },
  {
    path: "/permissions/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUserPermissions {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: (
      <Errors
        number="500"
        message="There was a problem on our end. Please try again later"
        text="Something went wrong"
      />
    )
  },
  // {
  //   path: "/components-overview",
  //   layout: DefaultLayout,
  //   component: ComponentsOverview
  // },
  // {
  //   path: "/tables",
  //   layout: DefaultLayout,
  //   component: Tables
  // },
  // {
  //   path: "/blog-posts",
  //   layout: DefaultLayout,
  //   component: BlogPosts
  // },
  {
    path: "/organisations",
    layout: DefaultLayout,
    component: Organisations
  },
  {
    path: "/manage-org/:id?",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageOrganisations {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/login",
    layout: CustomLayout,
    component: () => (loggedIn ? <Redirect to="/dashboard" /> : <Login />)
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: () => (loggedIn ? <EditProfile /> : <Redirect to="/login" />)
  },
  {
    path: "/user-request",
    layout: DefaultLayout,
    component: () => (loggedIn ? <UserRequest /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-customer/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUsers {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-customer/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageUsers {...props} /> : <Redirect to="/login" />
  },
  // {
  //   path: "/manage-customer/accept/:id",
  //   layout: DefaultLayout,
  //   component: props =>
  //     loggedIn ? <ManageUsers {...props} /> : <Redirect to="/login" />
  // },
  {
    path: "/folios",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Folios /> : <Redirect to="/login" />)
  },
  {
    path: "/manage-folio/create",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageFolios {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/manage-folio/:type/:id",
    layout: DefaultLayout,
    component: props =>
      loggedIn ? <ManageFolios {...props} /> : <Redirect to="/login" />
  },
  {
    path: "/folio-consumptions",
    layout: DefaultLayout,
    component: () =>
      loggedIn ? <FolioConsumptions /> : <Redirect to="/login" />
  },
  // {
  //   path: "/manage-folio-consumption/create",
  //   layout: DefaultLayout,
  //   component: props =>
  //     loggedIn ? (
  //       <ManageFolioConsumption {...props} />
  //     ) : (
  //       <Redirect to="/login" />
  //     )
  // },
  // {
  //   path: "/manage-folio-consumption/:type/:id",
  //   layout: DefaultLayout,
  //   component: props =>
  //     loggedIn ? (
  //       <ManageFolioConsumption {...props} />
  //     ) : (
  //       <Redirect to="/login" />
  //     )
  // },
  {
    path: "/settings",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Settings /> : <Redirect to="/login" />)
  },
  {
    path: "/CertProcess",
    layout: DefaultLayout,
    component: () => (loggedIn ? < CertProcess/> : <Redirect to="/login" />)
  },
  {
    path: "/IntercambioDocuments",
    layout: DefaultLayout,
    component: () => (loggedIn ? < IntercambioDocuments/> : <Redirect to="/login" />)
  },
  {
    path: "/PurchaseDocuments",
    layout: DefaultLayout,
    component: () => (loggedIn ? < PurchaseDocuments/> : <Redirect to="/login" />)
  },
  {
    path: "/IntercambioVentas",
    layout: DefaultLayout,
    component: () => (loggedIn ? < IntercambioVentas/> : <Redirect to="/login" />)
  },
  {
    path: "/customers",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Users /> : <Redirect to="/login" />)
  },
  {
    path: "/charts",
    layout: DefaultLayout,
    component: () => (loggedIn ? <Charts /> : <Redirect to="/login" />)
  },
  {
    path: "/logout",
    layout: CustomLayout,
    component: () => logout()
  },
  {
    path: "/404",
    layout: DefaultLayout,
    component: () => (
      <Errors
        exact={true}
        number="404"
        message="Not Fount"
        text="Page you are looking was not found !!"
      />
    )
  }
];
