import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormCheckbox
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordStrengthBar from "react-password-strength-bar";
import styles from "./../Style.module.scss";
import { Redirect, Link } from "react-router-dom";
import { strings } from "./../../localization/Customer";
import { API_URL, Language, updateNumber } from "./../../config";
import OrganisationSearch from "./../../components/organisation-search";

// import Avatar from "react-avatar-edit";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.state = {
      phone: "",
      email: "",
      address: "",
      username: "",
      password: "",
      loader: "",
      full_name: "",
      org_id: "",
      src: "",
      type: "create",
      org_code: null,
      id: "",
      button: false,
      hide: true,
      redirect: false,
      status: true,
      hasRestaurantModulePermission: false,
      selectedUserRole: "",
      userRoleData: [],
      organisationData: []
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    this.getUserRoles();
    if (type !== undefined) {
      this.setState({
        type: type
      });
      if (id) {
        fetch(`${API_URL}users/${id}`, {
          method: "GET",
          credentials: "include"
        })
          .then(res => res.json())
          .then(({ data }) => {
            this.setState(
              {
                phone: data.phone,
                email: data.email,
                address: data.address,
                org_id: data.organisationID,
                full_name: data.fullName,
                username: data.userName,
                status: data.status,
                id: "/" + id,
                selectedUserRole: data.restaurantUserRole
              },
              () => this.fetchOrg()
            );
          })
          .catch(e => {
            // alert(e)
          });
      }
    } else {
      this.fetchOrg();
    }
  }

  fetchOrg = () => {
    fetch(`${API_URL}organisations`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result) {
          let array = [];
          res.result.map(item => {
            let value = item._id;
            let label = item.data.name;

            if (this.state.org_id === value) {
              this.setState({
                org_code: { label: label, value: value }
              });
            }

            var obj = {};
            obj = Object.assign({ label: label, value: value }, obj);
            array.push(obj);
          });
          const orgData = res.result !== undefined || res.result !== null ? res.result : []
          this.setState({
            options: array,
            organisationData: orgData
          },
            () => this.hasRestaurantModulePermission(this.state.org_id)
          )
            ;
        }
      })
      .catch(err => {
        // console.log(err);
      });
  };

  hideSwitch = () => {
    this.setState({ hide: !this.state.hide });
  };
  Capitalize(str) {
    return str !== "edit" ? strings.create : strings.edit;
  }
  handleChange = selectedOption => {
    this.setState({ org_code: selectedOption, orgcode: selectedOption.value, selectedUserRole: "" });
    this.hasRestaurantModulePermission(selectedOption.value);
  };

  getUserRoles = async () => {
    let hasPermission = false;
    let organizationData = JSON.parse(localStorage.getItem("organisation_info"));
    if (organizationData !== undefined && organizationData !== null &&
      organizationData['data']['restaurantModule'] !== undefined && organizationData['data']['restaurantModule'] !== null
    ) {
      hasPermission = organizationData['data']['restaurantModule'];
      // this.setState({
      //   hasRestaurantModulePermission: hasPermission
      // })
    }
    console.log('hasPermission', hasPermission)
    if (hasPermission) {
      fetch(`${API_URL}user-role?module=restaurant`, {
        method: "GET",
        credentials: "include"
      })
        .then(res => res.json())
        .then(({ result }) => {
          console.log('response of role api', result)
          if (result) {
            this.setState({
              userRoleData: result
            })
          }
        });
    }
  }

  handleUserRoleChange(e) {
    this.setState({
      selectedUserRole: e.target.value
    })
  }

  hasRestaurantModulePermission(value) {
    let hasPermission = false;
    if (value) {
      const matchedOrg = this.state.organisationData.find((item) => item._id === value);
      console.log('data', matchedOrg);
      if (matchedOrg && matchedOrg['data']['restaurantModule']) {
        hasPermission = true;
      }
    }
    this.setState({
      hasRestaurantModulePermission: hasPermission
    })
  }

  render() {
    const {
      phone,
      email,
      address,
      username,
      password,
      hide,
      org_code,
      full_name,
      status,
    } = this.state;
    const handleClick = () => {
      if (
        phone === "" ||
        email === "" ||
        address === "" ||
        username === "" ||
        org_code === "" ||
        (password === "" && this.state.id == "")
      ) {
        toast.error(strings.fill_fields, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        let obj = {
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address,
          organisationID: this.state.orgcode,
          userName: username,
          status: this.state.status,
          fullName: full_name,
          restaurantUserRole: this.state.selectedUserRole
        };

        if (this.state.password !== "") {
          obj.password = this.state.password;
        }

        let strings = JSON.stringify(obj);

        fetch(`${API_URL}users${this.state.id}`, {
          method: this.state.id ? "put" : "post",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: strings
        })
          .then(res => {
            if (res.status == 200) {
              toast.success("Record saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });

              this.setState({ loader: "", button: false, password: "" });
            }
            return res.json();
          })
          .then(res => {
            if (res.message) {
              toast.error(res.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
            this.setState({ loader: "", button: false });
          })
          .catch(err => {
            console.warn(err);
            this.setState({ loader: "", button: false });
          });
      }
    };



    return (
      <div>
        {this.state.redirect ? <Redirect push to="/customers" /> : null}
        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              title={this.Capitalize(this.state.type)}
              subtitle={strings.overview}
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="6" md="10" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form id="ProfileForm">
                          <label htmlFor="org_code">{strings.org_name}</label>
                          <OrganisationSearch
                            org_code={this.state.org_code}
                            handleChange={this.handleChange}
                          />

                          <label
                            htmlFor="username"
                            style={{ marginTop: "10px" }}
                          >
                            Username
                          </label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">code</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="username"
                              placeholder="Username"
                              value={username}
                              onChange={e =>
                                this.setState({ username: e.target.value })
                              }
                              onBlur={() => {
                                let value = updateNumber(username);
                                this.setState({
                                  username: value
                                });
                              }}
                            />{" "}
                          </InputGroup>
                          <label htmlFor="full_name">{strings.f_name}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">person</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="full_name"
                              value={full_name}
                              placeholder={strings.f_name}
                              onChange={e =>
                                this.setState({ full_name: e.target.value })
                              }
                            />
                          </InputGroup>
                          {/* <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="phone">{strings.phone}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">phone</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="number"
                              id="phone"
                              value={phone}
                              placeholder={strings.phone}
                              onChange={e =>
                                this.setState({ phone: e.target.value })
                              }
                            />
                          </InputGroup>

                          {/* </Col>
                          </Row> */}
                          {/* <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="email">{strings.email}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">email</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="email"
                              placeholder={strings.email}
                              value={email}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />{" "}
                          </InputGroup>

                          <label htmlFor="email">{strings.address}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">business</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type="text"
                              id="address"
                              placeholder={strings.address}
                              value={address}
                              onChange={e =>
                                this.setState({ address: e.target.value })
                              }
                            />{" "}
                          </InputGroup>



                          {/* </Col>
                          </Row> */}
                          {/* <Row form>
                            <Col lg="12" className="form-group"> */}
                          {/* <InputGroup seamless className="mb-3"> */}
                          {/* <InputGroupAddon type="prepend"> */}
                          {/* <InputGroupText>
                              <i className="material-icons">apartment</i>
                              </InputGroupText>
                            </InputGroupAddon> */}
                          {/* <FormInput
                              type="text"
                              id="org_code"
                              placeholder={strings.org_code}
                              value={org_code}
                              onChange={e =>
                                this.setState({ org_code: e.target.value })
                              }
                            /> */}
                          {/* </InputGroup> */}

                          {/* </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group"> */}

                          {/* </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group"> */}
                          <label htmlFor="password">{strings.password}</label>
                          <InputGroup seamless className="mb-3">
                            <InputGroupAddon type="prepend">
                              <InputGroupText>
                                <i className="material-icons">vpn_key</i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <FormInput
                              type={hide ? "password" : "input"}
                              id="password"
                              placeholder={strings.password}
                              value={password}
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                            />{" "}
                            <span
                              className={styles.password__show}
                              onClick={this.hideSwitch}
                            >
                              {hide ? (
                                <i
                                  className="material-icons"
                                  style={{ top: "-5px", zIndex: 999 }}
                                >
                                  visibility
                                </i>
                              ) : (
                                <i
                                  className="material-icons"
                                  style={{ top: "-5px", zIndex: 999 }}
                                >
                                  visibility_off
                                </i>
                              )}
                            </span>
                          </InputGroup>

                          <PasswordStrengthBar password={password} />

                          {
                            this.state.hasRestaurantModulePermission &&
                            <select
                              className="form-control mb-3 mt-3"
                              onChange={e => this.handleUserRoleChange(e)}
                              value={this.state.selectedUserRole}
                            >
                              <option value="" disabled>
                                {strings.select_user_role}
                              </option>
                              {
                                this.state.userRoleData &&
                                this.state.userRoleData.length > 0 &&
                                this.state.userRoleData.map((userRole, index) => {
                                  return <option label={userRole['label']} value={userRole['value']} />
                                })
                              }
                            </select>
                          }

                          {/* {this.state.type === "edit" ? ( */}
                          <InputGroup seamless className="mb-3">
                            <FormCheckbox
                              toggle
                              medium
                              onChange={() =>
                                this.setState({
                                  status: !this.state.status
                                })
                              }
                              checked={status}
                            >
                              Status
                            </FormCheckbox>
                          </InputGroup>
                          {/* ) : null} */}
                          {/* </Col>
                          </Row> */}
                          {/* <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="profile">Profile Picture</label>
                              <div className="mt-2 d-flex justify-content-around">
                                {/* <Avatar
                                  height={200}
                                  imageWidth={200}
                                  onCrop={this.onCrop}
                                  onClose={this.onClose}
                                /> */}
                          {/* <img
                                  src={this.state.preview}
                                  height="100"
                                  alt="Preview"
                                /> 
                              </div>
                            </Col>
                          </Row> */}
                          <ToastContainer />
                          <Button
                            theme="accent"
                            onClick={() => handleClick()}
                            disabled={this.state.button}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>{" "}
                            {this.Capitalize(this.state.type)}
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              {/* {this.state.type !== "create" && ( */}
              <Link to={"/customers"} className="float-left">
                <Button
                  size="sm"
                  id="go_back"
                  theme="light"
                  className="mb-2 mr-1"
                  style={{
                    marginTop: "-84px",
                    position: "relative",
                    left: "-100px"
                  }}
                >
                  {strings.go_back}
                </Button>
              </Link>
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
