import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import { Link } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import SearchFilter from "./../search_filter";
import { strings } from "../../localization/Inquire";
import { Language, API_URL, LIMIT } from "../../config";
import { Container, Row, Col, Card, Button, CardBody } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import OrganisationSearch from "./../../components/organisation-search";

import swal from "sweetalert2";
window.Swal = swal;
const filters = [
  {
    name: "User Name"
  },
  {
    name: "Email"
  },
  {
    name: "Phone"
  }
];
class Customers extends React.Component {
  state = {
    alert: null,
    records: [],
    total: 0,
    skip: 0,
    limit: LIMIT,
    currentPage: 1,
    prev: true,
    loading: true,
    next: false
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);

    this.columns = [
      {
        key: "userName",
        text: "Username"
      },
      {
        key: "email",
        text: strings.email
      },
      {
        key: "phone",
        text: strings.phone
      },
      {
        key: "action",
        text: strings.action,
        className: "action",
        align: "left",
        sortable: false,
        cell: record => {
          return (
            <div className="mt-2">
              <Link
                to={{
                  pathname: "/manage-customer/edit/" + record.id
                }}
              >
                <Button size="sm" theme="success" className="mb-2 mr-1">
                  {strings.edit}
                </Button>
              </Link>
              <Button
                size="sm"
                theme="danger"
                className="mb-2 mr-1"
                onClick={() => this.deleteCustomer(record.id)}
              >
                {strings.delete}
              </Button>
              <Link
                to={{
                  pathname: "/permissions/" + record.id
                }}
              >
                <Button size="sm" theme="warning" className="mb-2 mr-1">
                  Permissions
                </Button>
              </Link>
            </div>
          );
        }
      }
    ];
    this.config = {
      show_length_menu: false,
      show_pagination: false,
      show_info: false
    };
  }

  // editRecord(record) {
  //   console.log("Edit Record", record);
  // }
  deleteCustomer(id) {
    swal
      .fire({
        title: strings.are_you_sure,
        text: strings.delete_customer,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E209D",
        cancelButtonColor: "#D61E11",
        confirmButtonText: strings.yes_delete,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(`${API_URL}users/${id}`, {
            method: "delete",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
          })
            .then(res => {
              if (res.status == 200) {
                this.getData();

                swal.fire(
                  strings.deleted,
                  "User deleted successfully.",
                  "success"
                );
              }
              return res.json();
            })
            .catch(err => {
              swal.showValidationMessage(`Request failed: ${err}`);
            });
        }
      })
      .then(result => {});
  }

  componentDidMount() {
    this.getData();
    let user_info = localStorage.getItem("user_info");
    user_info = JSON.parse(user_info);

    fetch(`${API_URL}permissions?userid=${user_info._id}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          permissions: data.data.permissions
        });
      })
      .catch(e => {});
  }

  getData = (skip = 0, params = "") => {
    // alert(queryString);
    fetch(`${API_URL}users?limit=${this.state.limit}&skip=${skip}${params}`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result.length > 0) {
          let array = [];

          res.result.map(item => {
            let obj = item.data;
            obj = Object.assign({ id: item._id }, obj);
            array.push(obj);
          });

          if (res.result.length !== this.state.limit) {
            this.setState({
              next: true
            });
          }

          this.setState({
            total: res.result.length + 1,
            records: array,
            loading: false
          });
        } else {
          this.setState({
            next: true,
            loading: false,
            records: []
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  };

  tableChangeHandler = data => {
    let queryString = Object.keys(data)
      .map(key => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    // alert(queryString);
    this.getData(queryString);
  };

  pageIncrement() {
    if (!this.state.next) {
      let skip = this.state.currentPage * this.state.limit;
      this.getData(skip);
      this.setState({
        currentPage: this.state.currentPage + 1,
        prev: false,
        skip: skip
      });
    }
  }

  pageDecrement() {
    if (this.state.skip > 0) {
      let skip = this.state.skip - this.state.limit;
      if (skip >= 0) {
        this.getData(skip);
        if (skip === 0) {
          this.setState({
            prev: true
          });
        }
        this.setState({
          currentPage: this.state.currentPage - 1,
          next: false,
          skip: skip
        });
      } else {
        this.setState({
          prev: true,
          next: false
        });
      }
    } else {
      this.setState({
        next: false
      });
    }
  }

  handleChange = selectedOption => {
    if (selectedOption.value) {
      let param = "&filter=true&f_organisationID=" + selectedOption.value;
      this.getData(0, param);
    } else {
      this.getData();
    }
    this.setState({
      org_code: selectedOption,
      orgcode: selectedOption.value,
      loading: true
    });
  };

  render() {
    //Datatable HTML
    const { permissions } = this.state;

    let status = "";
    try {
      status = permissions["all_users"];
    } catch {
      status = false;
    }

    // console.log(status);

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Users"
            subtitle="overview"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <Row>
                  <Col>
                    <SearchFilter getData={this.getData} filters={filters} />
                  </Col>
                  {status === true && (
                    <Col>
                      <OrganisationSearch
                        handleChange={this.handleChange}
                        isFilter={true}
                      />
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-3 pb-3">
                <Link to="manage-customer/create">
                  <Button
                    size="sm"
                    theme="primary"
                    className="mb-2 float-right"
                  >
                    <i class="material-icons">add</i> Create User
                  </Button>
                </Link>
                <div className="MainDiv">
                  <div className="container">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={this.config}
                        records={this.state.records}
                        columns={this.columns}
                        loading={this.state.loading}
                      />

                      <nav className="d-flex justify-content-center align-items-center">
                        <ul className="pagination">
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="javascript:;"
                              onClick={() => this.pageDecrement()}
                              style={
                                this.state.prev
                                  ? { cursor: "not-allowed", color: "gray" }
                                  : null
                              }
                            >
                              Previous
                            </a>
                          </li>
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="javascript:;"
                              onClick={() => this.pageIncrement()}
                              style={
                                this.state.next
                                  ? { cursor: "not-allowed", color: "gray" }
                                  : null
                              }
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <ToastContainer /> */}
      </Container>
    );
  }
}
export default Customers;
