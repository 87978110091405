import React from 'react'
import { Button, Container, Card } from "shards-react";
import { Row, Col, Form, FormGroup, InputGroup, CardBody } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import { API_URL } from "../config";
import ReactDatatable from "@ashvin27/react-datatable";
import OrganisationSearch from "./../components/organisation-search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-loader-spinner";
export const MarkAsRead = (props) => {
    const [btnLoading, setBtnLoading] = React.useState(false)
    const updateStatus = () => {
        setBtnLoading(true)
        let bodyStrings = JSON.stringify({
            status: 'SUBMITTED'
        });
        let statusCode = "";
        console.log(`${API_URL}intercambioDocuments/${props.id}`)
        fetch(`${API_URL}intercambioDocuments/${props.id}`, {
            method: "put",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: bodyStrings
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log(res)
                setBtnLoading(false)
                if (statusCode === 200) {
                    props.fetchData()
                }
            })
            .catch(err => {
                console.log(err);
                setBtnLoading(false)
            });

    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', }}>
            <Button
                disabled={props.status === "SUBMITTED"}
                theme="success"
                className="mb-2 mr-1"
                onClick={() => {
                    updateStatus()
                }}
            >
                Mark as submited
            </Button>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 5
                }}
            >
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={40}
                    width={40}
                    visible={btnLoading}
                />
            </div>
        </div>
    )
}
const IntercambioDocuments = () => {
    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const [organisationList, setorganisationList] = React.useState([]);
    const [organizationId, setOrganitationId] = React.useState('');
    const [documentType, setDocumentType] = React.useState("PENDING")
    const [records, setRecords] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    const [startDate, setStartDate] = React.useState(yesterday);
    const columns = [

        {
            key: "organisationRutNumber",
            text: "RUT Number"
        },
        {
            key: "organisationName",
            text: "Organisation Name"
        },
        {
            key: "status",
            text: "Status"
        },
        {
            key: "totalDocuments",
            text: "Total Documents"
        },
        {
            key: "_id",
            text: "Action",
            className: "action",
            cell: (record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            theme="primary"
                            className="mb-2 mr-2"
                            onClick={() => downloadFile(record.xmlData)}
                        >
                            Download XML
                        </Button>
                        <MarkAsRead id={record._id} fetchData={fetchData} status={record.status} />
                    </div>
                );
            }
        }
    ];
    const config = {
        page_size: 10,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    const fillOrganisation = () => {
        fetch(`${API_URL}organisations`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                if (res.result.length > 0) {
                    let array = [];

                    array.push({
                        id: "",
                        name: "All Organisation"
                    });

                    res.result.map(item => {
                        let obj = item.data;

                        array.push({
                            id: item._id,
                            rutNumber: item.data.rutNumber,
                            name: item.data.name
                        });
                    });

                    setorganisationList(array);
                    console.log(array);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
    React.useEffect(() => fillOrganisation(), []);

    const downloadFile = (xmlData) => {
        let data = xmlData
        var filename = "ebol_purchased_documents.xml";
        var pom = document.createElement('a');
        var bb = new Blob([data], {type: 'text/xml'});

        pom.setAttribute('href', window.URL.createObjectURL(bb));
        pom.setAttribute('download', filename);

        pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':');
        pom.draggable = true; 
        pom.classList.add('dragout');

        pom.click();
    }

    const fetchData = () => {
        setLoading(true);
        let date = startDate.toLocaleDateString()
        let statusCode = "";
        console.log(`${API_URL}intercambioDocuments?status=${documentType}&organisationID=${organizationId}&date=${date}`);
        fetch(`${API_URL}intercambioDocuments?status=${documentType}&organisationID=${organizationId}&date=${date}`, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log(res);
                setLoading(false)
                if (statusCode === 200) {
                    setRecords(res.result);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }

    const handleOrganisationChange = e => {
        let orgID = e.value;
        console.log("organisation Id is...", orgID);
        setOrganitationId(orgID);
    };

    const handleChange = (e) => {
        console.log('values....', e.target.value)
        setDocumentType(e.target.value)
    }

    React.useEffect(() => {
        fetchData()
    }, [organizationId, documentType, startDate])
    return (
        <Container
            fluid
            className="main-content-container px-4"

            style={{ marginBottom: "200px" }}
        >
            <Row noGutters className="page-header py-4">
                <PageTitle title="Intercambio Documents" className="text-sm-left mb-3" />
            </Row>

            <Row>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <Col>
                                <OrganisationSearch
                                    handleChange={handleOrganisationChange}
                                    isFilter={true}
                                />
                            </Col>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <select value={documentType}
                                className="form-control"
                                onChange={e => handleChange(e)}
                            >
                                {/* <option value="ALL">All</option> */}
                                <option value="SUBMITTED">Submited</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <DatePicker
                                className="form-control"
                                selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                    </Card>
                </Col>
            </Row>


            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    visible={loading}
                                />
                            </div>
                            <div className="MainDiv">
                                <div className="table-responsive">
                                    {!loading && <ReactDatatable
                                        config={config}
                                        records={records}
                                        columns={columns}
                                        loading={loading}
                                    />}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </Container>
    )
}

export default IntercambioDocuments


