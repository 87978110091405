import React, { Component } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Container
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../config";
// import styles from "./../Style.module.scss";

// import Avatar from "react-avatar-edit";

export default class EditUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalCertificateFilePath: "",
      superAdminOrgCode: "",
      SuperAdminUserName: "",
      DbArchiveFrequency: "",
      verificationLinkOnPrint: "",
      button: false,
      hide: true
    };
  }

  componentDidMount() {
    fetch(`${API_URL}settings`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(data => {
        let info = data.data;
        let id = data._id;
        // console.log(info);
        if (info) {
          this.setState({
            globalCertificateFilePath: info.globalCertificateFilePath,
            superAdminOrgCode: info.superAdminOrgCode,
            SuperAdminUserName: info.SuperAdminUserName,
            DbArchiveFrequency: info.DbArchiveFrequency,
            verificationLinkOnPrint: info.verificationLinkOnPrint,
            method: "put",
            id: id
          });
        } else {
          // alert("in");
          // this.setState({ method: "put" });
        }
      })
      .catch(e => {
        // alert("catch");
        // this.setState({
        //   visible: false,
        // });
      });
  }

  render() {
    const {
      globalCertificateFilePath,
      superAdminOrgCode,
      SuperAdminUserName,
      verificationLinkOnPrint,
      DbArchiveFrequency
    } = this.state;
    const handleClick = () => {
      if (
        globalCertificateFilePath === "" ||
        superAdminOrgCode === "" ||
        SuperAdminUserName === "" ||
        verificationLinkOnPrint === "" ||
        DbArchiveFrequency === ""
      ) {
        toast.error("Fill All Fields !!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        this.setState({ loader: "fa fa-spinner fa-spin", button: true });

        // let user_info = localStorage.getItem("login_info");
        // user_info = JSON.parse(user_info);
        // var user_id = user_info.id;

        let data = "/" + this.state.id;

        let body = JSON.stringify({
          globalCertificateFilePath,
          superAdminOrgCode,
          SuperAdminUserName,
          verificationLinkOnPrint,
          DbArchiveFrequency
        });
        let responseStatus = "";
        fetch(`${API_URL}settings${data}`, {
          method: "PUT",
          headers: {
            "Content-Type": "Application/json"
          },
          credentials: "include",
          body: body
        })
          .then(res => {
            responseStatus = res.status;
            return res.json();
          })
          .then(data => {
            // console.log(data);
            if (responseStatus === 400) {
              toast.error(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            } else {
              toast.success("Record saved successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              // this.setState({ button: false, loader: "" });
            }
            this.setState({ loader: "", button: false });
          });

        // setTimeout(() => {
        //   toast.success("Setting save successfully", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: false,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined
        //   });

        //   this.setState({ loader: "", button: false });
        // }, 1000);
      }
    };
    return (
      <div>
        <Container
          fluid
          className="main-content-container px-4"
          style={{ marginBottom: "200px" }}
        >
          <Row noGutters className="page-header py-4">
            <PageTitle
              title="Settings"
              subtitle="Overview"
              md="12"
              className="ml-sm-auto mr-sm-auto"
            />
          </Row>
          <Row>
            <Col lg="6" md="10" sm="12">
              <Card small className="mb-4">
                <ListGroup flush>
                  <ListGroupItem className="p-3">
                    <Row>
                      <Col>
                        <Form id="ProfileForm">
                          <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="vcode">
                                Global certificate file path
                              </label>
                              <FormInput
                                type="text"
                                id="vcode"
                                value={globalCertificateFilePath}
                                placeholder="Global certificate file path"
                                onChange={e =>
                                  this.setState({
                                    globalCertificateFilePath: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="superAdminOrgCode">
                                Super Admin org code
                              </label>
                              <FormInput
                                type="text"
                                id="superAdminOrgCode"
                                value={superAdminOrgCode}
                                placeholder="Super Admin org code"
                                onChange={e =>
                                  this.setState({
                                    superAdminOrgCode: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="SuperAdminUserName">
                                Super Admin user name
                              </label>
                              <FormInput
                                type="text"
                                id="SuperAdminUserName"
                                value={SuperAdminUserName}
                                placeholder="Super Admin user name"
                                onChange={e =>
                                  this.setState({
                                    SuperAdminUserName: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="DbArchiveFrequency">
                                DB archive frequency
                              </label>
                              <FormInput
                                type="text"
                                id="DbArchiveFrequency"
                                value={DbArchiveFrequency}
                                placeholder="DB archive frequency"
                                onChange={e =>
                                  this.setState({
                                    DbArchiveFrequency: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col lg="12" className="form-group">
                              <label htmlFor="verificationLinkOnPrint">
                                Verification Link On Print
                              </label>
                              <FormInput
                                type="text"
                                id="verificationLinkOnPrint"
                                value={verificationLinkOnPrint}
                                placeholder="Verification Link On Print"
                                onChange={e =>
                                  this.setState({
                                    verificationLinkOnPrint: e.target.value
                                  })
                                }
                              />
                            </Col>
                          </Row>

                          <ToastContainer />
                          <Button
                            theme="accent"
                            onClick={() => handleClick()}
                            disabled={this.state.button}
                          >
                            <i
                              className={this.state.loader}
                              style={{ fontSize: "15px" }}
                            ></i>{" "}
                            Save Settings
                          </Button>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
