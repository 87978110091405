import React from 'react'
import { Button, Container, Card } from "shards-react";
import { Row, Col, Form, FormGroup, InputGroup, CardBody } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import { API_URL } from "../config";
import ReactDatatable from "@ashvin27/react-datatable";
import OrganisationSearch from "./../components/organisation-search";
import Loader from "react-loader-spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";


const IntercambioVentas = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let start_date = mm + "/" + "01" + "/" + yyyy;
    let end_date = mm + "/" + dd + "/" + yyyy;
    const [startDate, setStartDate] = React.useState(start_date)
    const [endDate, setEndDate] = React.useState(end_date)
    const [startDateApi, setStartDateApi] = React.useState("")
    const [endDateApi, setEndDateApi] = React.useState("")
    const [locale, setLocale] = React.useState({
        format: "DD/MM/YYYY"
    })
    const [organizationId, setOrganitationId] = React.useState('');
    const [documentType, setDocumentType] = React.useState("ALL")
    const [records, setRecords] = React.useState([{
        _id: 545154,
        organisationName: 'Google Inc.',
        organisationRutNumber: '102343115',
        folio: '4546464',
        mntTotal: '10112',
        createdAt:'1/2/2022'
    },
    {
        _id: 54545,
        organisationName: 'Google Analytics',
        organisationRutNumber: '10234315455',
        folio: '45468454',
        mntTotal: '15462',
        createdAt:'1/2/2022'

    }])
    const [loading, setLoading] = React.useState(false)
    const [checked, setChecked] = React.useState([]);
    const [allDocumentsChecked, setAllDocumentsChecked] = React.useState(false)
    const [isArchiveButtonDesabled, setIsArchiveButtonDesabled] = React.useState(true)
    console.log("Check box values....", checked)


    React.useEffect(() => {
        let result = checked.find(item => {
            if (item) return item
        });
        if (result) {
            setIsArchiveButtonDesabled(false)
        } else (
            setIsArchiveButtonDesabled(true)
        )

    }, [checked])
    const columns = [
        documentType !== 'ARCHIVED' && records.length > 0 && {
            key: "",
            text: <input type="checkbox"
                defaultChecked={allDocumentsChecked}
                onChange={() => {
                    setChecked([])

                    if (allDocumentsChecked) {
                        setChecked(new Array(records.length).fill(false));
                        setAllDocumentsChecked(false)
                    } else {
                        setChecked(new Array(records.length).fill(true));
                        setAllDocumentsChecked(true)
                    }
                }}
            />,

            cell: (record, index) => {
                return (
                    <input type="checkbox"
                        checked={checked[index]}
                        onChange={() => {
                            const updatedCheckedState = checked.map((item, checkIndex) =>
                                checkIndex === index ? !item : item
                            );
                            setChecked(updatedCheckedState);
                        }}
                    />
                )
            }
        },
        {
            key: "createdAt",
            text: "Created At"
        },
        {
            key: "organisationName",
            text: "Organisation Name"
        },
        {
            key: "organisationRutNumber",
            text: "RUT Number"
        },
        {
            key: "folio",
            text: "Folio"
        },
        {
            key: "mntTotal",
            text: "Monto Total"
        },

        {
            key: "_id",
            text: "Action",
            className: "action",
            cell: (record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            theme="primary"
                            className="mb-2 mr-2"
                            onClick={() =>
                                downloadXMLFile(record._id)
                            }
                        >
                            Download XML
                        </Button>
                        {documentType !== 'ARCHIVED' && <Button
                            theme="primary"
                            className="mb-2 mr-2"
                            onClick={() => { onArchiveDocuments(record._id) }}
                        >
                            Archive
                        </Button>}
                    </div>
                );
            }
        }
    ];
    const config = {
        page_size: 10,
        show_length_menu: false,
        show_info: false,
        show_pagination: false,
        button: {
            excel: false,
            print: false,
            extra: false
        }
    };

    const downloadXMLFile = (id) => {

        let url = `${API_URL}downloadIntercambioVentasXml?id=${id}`
        let statusCode = "";
        console.log(url);
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log("Download xml api response......", res);
                if (statusCode === 200) {
                    let data = res.result;
                    var filename = "ebol_purchased_documents.xml";
                    var pom = document.createElement('a');
                    var bb = new Blob([data], { type: 'text/xml' });
                    pom.setAttribute('href', window.URL.createObjectURL(bb));
                    pom.setAttribute('download', filename);
                    pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':');
                    pom.draggable = true;
                    pom.classList.add('dragout');
                    pom.click();
                }
            })
            .catch(err => {
                console.log("Download xml api error......", err);
            });
    }


    const handleCallback = (start, end) => {
        var startDate = new Date(start);
        var startDateApi = startDate.setDate(startDate.getDate() + 1);
        startDateApi = startDate.toISOString().slice(0, 10);;
        var endDate = new Date(end);
        var endDateApi = endDate.toISOString().slice(0, 10);;
        setStartDate(start)
        setEndDate(end)
        setStartDateApi(startDateApi)
        setEndDateApi(endDateApi)
    };
    const fetchData = () => {
        let url = `${API_URL}allIntercambioVentas?status=${documentType}&organisationID=${organizationId}&fromDate=${startDateApi}&toDate=${endDateApi}`
        setLoading(true);
        let statusCode = "";
        console.log(url);
        fetch(url, {
            method: "GET",
            credentials: "include"
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log(res);
                setLoading(false)
                if (statusCode === 200) {
                    setRecords(res.result);
                    setChecked(new Array(res.result.length).fill(false));
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
    }

    const handleOrganisationChange = e => {
        let orgID = e.value;
        console.log("organisation Id is...", orgID);
        setOrganitationId(orgID);
    };

    const handleChange = (e) => {
        console.log('values....', e.target.value)
        setDocumentType(e.target.value)
        setChecked([])
        setAllDocumentsChecked(false)
    }

    React.useEffect(() => {
        fetchData()
    }, [organizationId, documentType, startDateApi, endDateApi])


    const onArchiveDocuments = (id) => {
        let selectedDocuments = []
        let statusCode = "";
        let url = `${API_URL}allIntercambioVentas`


        if (id) {
            selectedDocuments.push(id)
        } else {
            checked.map((item, index) => {
                if (item) {
                    selectedDocuments.push(records[index]._id)
                }
            })
        }
        console.log("Selected docuents id....", selectedDocuments)
        console.log("api url......", url)
        let bodyStrings = JSON.stringify(selectedDocuments);
        fetch(url, {
            method: "put",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: bodyStrings
        })
            .then(res => {
                statusCode = res.status;
                return res.json();
            })
            .then(res => {
                console.log("API response....", res)
                if (statusCode === 200) {
                    fetchData()
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    return (
        <Container
            fluid
            className="main-content-container px-4"

            style={{ marginBottom: "200px" }}
        >
            <Row noGutters className="page-header py-4">
                <PageTitle title="Intercambio Ventas" className="text-sm-left mb-3" />
            </Row>

            <Row>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <Col>
                                <OrganisationSearch
                                    handleChange={handleOrganisationChange}
                                    isFilter={true}
                                />
                            </Col>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <select value={documentType}
                                className="form-control"
                                onChange={e => handleChange(e)}
                            >
                                <option value="ALL">All</option>
                                <option value="ARCHIVED">Archived</option>
                            </select>
                        </div>
                    </Card>
                </Col>
                <Col lg="4" md="12" sm="12">
                    <Card small className="mb-4 p-4">
                        <div className="row">
                            <DateRangePicker
                                initialSettings={{
                                    startDate: startDate,
                                    endDate: endDate
                                }}
                                // onEvent={this.handleEvent}
                                onCallback={(start, end) =>
                                    handleCallback(start, end)
                                }
                                locale={locale}
                            >
                                <input type="text" className="form-control" />
                            </DateRangePicker>
                        </div>
                    </Card>
                </Col>
            </Row>


            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardBody className="p-3 pb-3">
                            <Button theme="primary" className="mb-2 float-left"
                                disabled={isArchiveButtonDesabled}
                                onClick={() => onArchiveDocuments()}
                            >
                                Archive
                            </Button>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    visible={loading}
                                />
                            </div>
                            <div className="MainDiv">
                                <div className="table-responsive">
                                    {!loading && <ReactDatatable
                                        config={config}
                                        records={records}
                                        columns={columns}
                                        loading={loading}
                                    />}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>


        </Container>
    )
}

export default IntercambioVentas


