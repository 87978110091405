import React, { Component } from "react";
import { strings } from "./../../localization/Customer";
import { API_URL, Language } from "./../../config";
import Select from "react-select";

export default class EditUserProfile extends Component {
  state = {
    org_code: "Select organisation code"
  };
  constructor(props) {
    super(props);
    strings.setLanguage(Language);
  }

  componentDidMount() {
    this.fetchOrg();
  }

  fetchOrg = () => {
    fetch(`${API_URL}organisations`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.json())
      .then(res => {
        if (res.result) {
          // console.log(this.state.org_id);
          let array = [];

          if (this.props.isFilter) {
            array.push({ label: "Show all", value: "" });
          }

          res.result.map(item => {
            let value = item._id;
            let label = item.data.name;

            if (this.state.org_id === value) {
              // alert("ok123");
              this.setState({
                org_code: { label: label, value: value }
              });
            }

            var obj = {};
            obj = Object.assign({ label: label, value: value }, obj);
            // console.log(obj);
            array.push(obj);
          });

          this.setState({
            options: array
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Select
        value={this.props.org_code}
        onChange={this.props.handleChange}
        options={this.state.options}
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
    );
  }
}
